import { useProvideAuth } from 'hooks/useProvideAuth';
/**
 * Custom hook for uploading a file when a driver picks up a car.
 * Uses authentication headers from `useProvideAuth` for secure requests.
 *
 * @returns {Object} An object containing the `uploadDriverPickedFile` function.
 */

/**
 * Uploads a file related to a driver picking up a car.
 *
 * @param {Object} params - The parameters for the upload.
 * @param {string} params.hash - A unique identifier for the transaction.
 * @param {File} params.driverPickedFile - The file to be uploaded.
 * @returns {Promise<Response>} A promise that resolves with the server response.
 * @throws {Error} If the upload fails.
 *
 * @example
 * ```ts
 * try {
 *   const response = await uploadDriverPickedFile({
 *     hash: 'abc123',
 *     driverPickedFile: file
 *   });
 *   console.log('Upload successful:', response);
 * } catch (error) {
 *   console.error('Upload failed:', error);
 * }
 * ```
 */
export const useUploadFileForDriverPicked = () => {
  const { getAuthHeaders } = useProvideAuth();
  const uploadDriverPickedFile = async ({
    hash,
    driverPickedFile,
  }: {
    hash: string;
    driverPickedFile: File;
  }) => {
    const formData = new FormData();
    console.log(`Uploading file`, {
      name: driverPickedFile.name,
      type: driverPickedFile.type,
      size: driverPickedFile.size,
    });
    formData.append('file', driverPickedFile);
    const response = await fetch(
      `${process.env.REACT_APP_FP_BE_URL}/api/upload-files/${hash}/driver-picked-car`,
      {
        method: 'POST',
        body: formData,
        headers: getAuthHeaders(),
      },
    );
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`File upload failed: ${errorMessage}`);
    }
    console.log(response);
    return response;
  };
  return {
    uploadDriverPickedFile,
  };
};
