/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-async-promise-executor */
import { useMutation } from '@apollo/client';
import parkingImage from 'assets/img/parking.png';
import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { UploadInput } from 'components/UploadInput/UploadInput';
import { CREATE_PRESIGNED_REQUEST } from 'graphql/mutations/createPresignedRequest';
import { useAccountType } from 'hooks/useAccountType';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FileData } from 'types/general';
import * as Comlink from 'comlink';
import { CREATE_GAS_STATION_PRESIGNED_REQUEST } from 'graphql/mutations/createGasStationPresignedRequest';
import { CREATE_GAS_STATION_REPORT_NEW } from 'graphql/mutations/createGasStationReportNew';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { QRCodeScannerIcon } from 'components/Icons/QRCodeScannerIcon/QRCodeScannerIcon';
import { Textarea } from 'components/Textarea/Textarea';
import { calculateSize } from 'utils/calculateSize';
import * as Sentry from '@sentry/react';
import {
  MAX_HEIGHT,
  MAX_WIDTH,
  MIME_TYPE,
  QUALITY,
} from 'data/imageCompression';
import { plateValidation } from 'data/regexPatterns';
import { SelectButton } from 'components/SelectButton/SelectButton';
import { generateNewFile } from 'utils/generateNewFile';
import { appContext } from 'views/App';
import { useProvideAuth } from 'hooks/useProvideAuth';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 5px;
  gap: 16px;
  .heading {
    margin-bottom: 16px;
  }
  .notesInput {
    height: max-content;
  }
  .gasStationSelectButton {
    height: auto;
  }
`;
const StyledParagraph = styled(Paragraph)`
  margin-bottom: 16px;
  margin-top: 5px;
`;
const StyledBottomContainer = styled.div`
  min-height: 16px;
`;

export const CreateNewReport = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { getAuthHeaders } = useProvideAuth();
  const { t } = useTranslation();
  const [isGasStationInStore, setIsGasStationInStore] = useState<boolean>(true);
  const navigate = useNavigate();
  const { type } = useParams();
  const methods = useForm({ mode: 'onSubmit' });
  const { setValue, getValues, watch, handleSubmit } = methods;
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const isQRScannerUser = userData?.qrCode;
  const onSubmit = () => {
    const files = reportDetails.videos
      ? [...reportDetails.images, ...reportDetails.videos]
      : [...reportDetails.images];
    getValues('qrCodeId') &&
      sessionStorage.setItem('qrCodeId', getValues('qrCodeId'));

    setReportDetails({
      ...reportDetails,
      type: Number(type),
      status: isNotUserAccount ? 'accepted' : 'new',
      notes: getValues('notes'),
      qrCodeId: getValues('qrCodeId'),
    });

    navigate(isUserAccount ? '/user-report-address' : '/order-address');
  };

  useEffect(() => {
    if (!reportDetails) return;
    if (!reportDetails.carDetails) return;
    setValue('license', reportDetails.carDetails.plate);
    setValue('notes', reportDetails?.notes);
    setValue('price', reportDetails?.price);
  }, [reportDetails]);
  // This will add licence plate number to reportDetails object
  useEffect(() => {
    const carDetails = {
      plate: getValues('license')?.toUpperCase().replaceAll(' ', ''), // error here
      carName: '',
    };
    console.log(carDetails);
    setReportDetails({ ...reportDetails, carDetails });
  }, [watch('license')]);

  useEffect(() => {
    const price = getValues('price');
    setReportDetails({ ...reportDetails, price });
  }, [watch('price')]);

  useEffect(() => {
    setReportDetails({ ...reportDetails, type: Number(type) });
  }, [type]);

  useEffect(() => {
    sessionStorage.removeItem('addedFilesCount');
  }, []);

  const handleBill = async (file: File) => {
    if (!file) return;
    setReportDetails({ ...reportDetails, bill: file });
  };

  const handleNewReportPhoto = async (file: File) => {
    if (file?.size >= 1000000) {
      const blobURL = file ? URL.createObjectURL(file) : '';
      const img = new Image();
      img.src = blobURL;
      img.onerror = function () {
        console.error('Cannot load image');
        Sentry.captureException(
          'Cannot load image in handleNewReportPhoto for upload',
        );
        URL.revokeObjectURL(img.src);
        throw new Error('Cannot load image');
      };
      img.onload = function () {
        try {
          URL.revokeObjectURL(img.src);
          const [newWidth, newHeight] = calculateSize(
            img,
            MAX_WIDTH,
            MAX_HEIGHT,
          );
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            (blob) => {
              const newFile = new File([blob], file.name, {
                type: blob.type,
              });
              const newFileWithNewName = generateNewFile(newFile);
              if (reportDetails?.images?.length > 0) {
                setReportDetails({
                  ...reportDetails,
                  images: [...reportDetails.images, newFileWithNewName],
                });
              } else {
                setReportDetails({
                  ...reportDetails,
                  images: [newFileWithNewName],
                });
              }
            },
            MIME_TYPE,
            QUALITY,
          );
        } catch (error) {
          console.error('Error occurred during image upload: ', error);
          Sentry.captureException(error);
        }
      };
    } else {
      const newFileWithNewName = generateNewFile(file); // TODO wtf is this?
      if (reportDetails?.images?.length > 0) {
        setReportDetails({
          ...reportDetails,
          images: [...reportDetails.images, newFileWithNewName],
        });
      } else {
        setReportDetails({ ...reportDetails, images: [newFileWithNewName] });
      }
    }
  };

  useEffect(() => {
    const qrCodeId = sessionStorage.getItem('qrCodeId');
    if (qrCodeId) setValue('qrCodeId', qrCodeId);
  }, []);

  useEffect(() => {
    if ((type === '2' && isNotUserAccount) || type === '3') {
      const textArea = document.getElementById('notes');
      textArea &&
        textArea.addEventListener('input', (evt) => {
          textArea.style.height = textArea.scrollHeight + 'px';
        });
    }
  }, [type, isNotUserAccount]);

  useEffect(() => {
    setReportDetails({
      ...reportDetails,
      inStore: isGasStationInStore,
    });
  }, [isGasStationInStore]);

  return (
    <Layout
      isBackBtn
      backgroundContent={<img src={parkingImage} width="100%" height="auto" />}
      mode="medium"
      backBtnLink="/"
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="reportDetailsForm">
          <div>
            <Heading
              color="var(--black)"
              isWithPaddingBottom={false}
              className="heading"
            >
              {t(
                type === '1'
                  ? 'badParkedCar'
                  : type === '2'
                  ? 'badParkedCarTicketConfirmationPickup'
                  : 'gasStationButtonSubtitle',
              )}
            </Heading>
            <Input
              name="license"
              label={t(
                reportDetails?.type === 3 ? 'controlPlate' : 'licensePlate',
              )}
              placeholder={t(
                reportDetails?.type === 3
                  ? 'controlPlatePlaceholder'
                  : 'licensePlatePlaceholder',
              )}
              mode="light"
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
              validation={plateValidation}
              validationErrorMessage={t('plateValidation')}
              autoComplete="off"
            />
            {isNotUserAccount && type === '1' && (
              <div>
                <Heading color="var(--black)" isWithPaddingBottom={false}>
                  {t('uploadVideoAndPhotos360')}
                </Heading>
                <StyledParagraph color="var(--sub--black)" dimension="s">
                  {t('uploadVideoAndPhotosInfo')}
                </StyledParagraph>
                <UploadInput
                  name="carVideo"
                  label={t('carVideo')}
                  mode="light"
                  handleMediaChange={(videos) => {
                    setReportDetails({ ...reportDetails, videos });
                  }}
                  onMediaClick={(video) =>
                    navigate(`/video-preview/${video.name}`)
                  }
                  mediaType="video"
                  bottomMarting="16"
                  isRequired={false}
                  addedMedia={reportDetails?.videos || []}
                />
              </div>
            )}
            <UploadInput
              name="carPhoto"
              label={t(type === '3' ? 'photo' : 'carPhoto')}
              mode="light"
              isMultiSelect
              onMediaClick={(image) =>
                image && navigate(`/photo-preview/${image?.name}`)
              }
              bottomMarting="16"
              addedMedia={reportDetails?.images || []}
              handleNewFile={(file) => handleNewReportPhoto(file)}
              isRequired={!reportDetails?.images}
            />
            {type === '3' && (
              <>
                <Input
                  name="price"
                  label={t('priceBill')}
                  placeholder={t('priceBillPlaceholder')}
                  mode="light"
                  isRequired
                  requiredErrorMessage={t('requiredError')}
                  bottomMarting="16"
                  type="text"
                  inputMode="decimal"
                  pattern="[0-9]*[.,]?[0-9]*"
                  autoComplete="off"
                  onChange={(e) => {
                    setValue('price', e.target.value.replace(',', '.'));
                  }}
                />
                <UploadInput
                  name="bill"
                  label={t('billPhoto')}
                  mode="light"
                  handleMediaChange={(images) => {
                    if (images?.length > 0) {
                      handleBill(images[0]);
                    }
                  }}
                  onMediaClick={(image) =>
                    navigate(`/photo-preview/${image.name}`)
                  }
                  bottomMarting="16"
                  addedMedia={
                    reportDetails?.bill ? [reportDetails?.bill] : undefined
                  }
                  isRequired={!reportDetails?.bill}
                />
                <SelectButton
                  renderedContent={
                    <Paragraph color="var(--black)">
                      {t('accidentalEvasion')}
                    </Paragraph>
                  }
                  onClick={() => setIsGasStationInStore(true)}
                  isSelected={isGasStationInStore}
                  className="gasStationSelectButton"
                />
                <SelectButton
                  renderedContent={
                    <Paragraph color="var(--black)">
                      {t('intensionalEvasion')}
                    </Paragraph>
                  }
                  onClick={() => setIsGasStationInStore(false)}
                  isSelected={!isGasStationInStore}
                  className="gasStationSelectButton"
                />
              </>
            )}
            {type === '2' && isQRScannerUser && (
              <div>
                <Input
                  name="qrCodeId"
                  label={t('ticketId')}
                  placeholder={t('ticketIdPlaceholder')}
                  mode="light"
                  bottomMarting="16"
                  icon={<QRCodeScannerIcon />}
                  onIconClick={(e) => {
                    e.preventDefault();
                    navigate('/qr-code-scanner');
                  }}
                  autoComplete="off"
                />
              </div>
            )}
            <Textarea
              name="notes"
              label={t('notes')}
              placeholder={t('notesPlaceholder')}
              mode="light"
              bottomMarting="16"
              contentClassName="notesInput"
              maxLength={255}
              autoComplete="off"
            />
          </div>
          <div>
            <Button type="submit">{t('next')}</Button>
            <StyledBottomContainer />
          </div>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};
