import { gql } from '@apollo/client';

export const CREATE_PARKING_ZONE = gql`
  mutation CreateParkingZone(
    $name: String!
    $fullAddress: String!
    $streetName: String!
    $streetNumber: String!
    $zipCode: String!
    $city: String!
    $signature: Upload
    $type: Int!
    $latitude: Float!
    $longitude: Float!
    $notes: String
  ) {
    createParkingZone(
      input: {
        name: $name
        address_full: $fullAddress
        city: $city
        street_name: $streetName
        street_number: $streetNumber
        zip_code: $zipCode
        type: $type
        latitude: $latitude
        longitude: $longitude
        notes: $notes
      }
      signature: $signature
    ) {
      id
    }
  }
`;
