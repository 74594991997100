export const qrCodeTickets = {
  cardriverDetailsTitle: 'Déclarer le conducteur du véhicule',
  carDriverDetailsDescription:
    "Si vous n'êtes pas responsable de l'indemnité de gestion, vous pouvez la transférer sur le conducteur du véhicule dans un délai de 2 jours.",
  firstName: 'Nom',
  lastName: 'Prénom',
  streetName: 'Rue',
  streetNumber: 'Numéro',
  zipCode: 'Code postal',
  city: 'Ville',
  email: 'Email',
  carDriverInfo: "Tous les champs marqués d'un astérisque sont obligatoires.",
  legalInformation: 'Informations légales',
  legalInformationElement:
    "Si votre plaque d'immatriculation est bloquée, vous avez 24 heures pour nous communiquer vos informations de propriétaire via le formulaire en ligne ou par e-mail à info@falsch-parker.ch. Sinon, des frais supplémentaires pourraient s'appliquer. \nStationnement illicite sur propriété privée (art. 641 + 926 + 927 + 928 CC) en vertu de la cession selon l'art. 164 ss. CO.",
  next: 'Suivant',
  back: 'Retour',
  chedckDetailsTitle: 'Vérifier et envoyer les informations',
  checkDetailsDescription: 'Veuillez vérifier les informations.',
  ticketNumberQR: "Numéro d'indemnité de gestion",
  plate: "Plaque d'immatriculation",
  countryCode: 'Code pays',
  notes: 'Remarque',
  fineAmount: 'Montant',
  zurichCityPolice: 'Police de la ville de Zurich',
  placeAndDateOfTheOffense: "Lieu et date de l'infraction",
  streetAndStreetNumber: 'Rue, numéro',
  zipCodeAndCity: 'Code postal, ville',
  timeAndDate: 'Date, heure',
  personalDetailsOfTheDriver: 'Détails personnels du conducteur',
  name: 'Nom / Prénom',
  addressSuffix: "Complément d'adresse",
  checkDetailsInfo: 'Je certifie que les informations fournies sont correctes.',
  send: 'Envoyer',
  failedViewTitle: 'Échec du paiement :(',
  failedViewSubtitle: 'Veuillez réessayer ou nous contacter',
  failedViewButton: 'Réessayer',
  objectionRaiseTitle: 'Soulever une objection',
  objectionRaiseSubtitle:
    "Pensez-vous que l'indemnité de gestion est injustifiée ou incorrecte?",
  objectionRaiseDescription:
    "Si votre objection est liée à l'utilisation d'une application de stationnement, veuillez joindre le reçu de l'opération concernée de l'application ou le télécharger en tant que fichier. Cela est nécessaire pour que nous puissions examiner votre objection.",
  note: 'Votre objection',
  notePlaveholder:
    'Veuillez justifier votre objection (maximum 2000 caractères)',
  objectionRaiseUpload: 'Joindre un fichier',
  addFile: 'Ajouter un fichier',
  addFileDesc: '(Max. 5 * 10 Mo, formats autorisés : pdf, png, jpg, bmp)',
  successPaymentTitle: 'Votre paiement a été réussi !',
  successPaymentSubtitle: 'Merci pour votre paiement',
  paymentInfoTitle: 'Payer / Informations de paiement',
  paymentInfoSubtitle:
    "Vous avez les options suivantes pour payer l'indemnité de gestion :",
  paymentMethodTicket: 'Carte de crédit, Postfinance Card, Twint ou E-Banking',
  pay: 'Payer',
  eBanking: 'E-Banking',
  bankAccountNumber: 'Numéro de compte',
  yourReferenceNo: 'Votre numéro de référence',
  amount: 'Montant',
  inFavorOf: 'En faveur de',
  paymentSlip: 'Bulletin de versement',
  paymentSlipDesc:
    "Si l'indemnité de gestion n'est pas payée dans les 2 jours suivant l'infraction de stationnement, nous enverrons automatiquement une indemnité de gestion avec un bulletin de versement à la personne responsable.",
  acceptAGB: "J'accepte les CGV",
  congratulations: 'Félicitations !',
  successCarOwnerAddingSubtitle:
    'Les informations concernant le conducteur ont été transmises',
  successObjectionAddingSubtitle: 'Votre objection a été transmise avec succès',
  ticketDetailsTitle: "Détails de l'indemnité de gestion",
  ticketDetaildNumber: "Numéro d'indemnité de gestion",
  parkingOffendersEmployees: 'Employés de Falschparker',
  placeAndDateOfTheParkingViolation:
    "Lieu et date de l'infraction de stationnement",
  objection: 'Objection',
  payNow: 'Payer maintenant',
  ticketTitle: "Veuillez entrer la \n plaque d'immatriculation",
  or: 'Et',
  plateNumber: "Plaque d'immatriculation",
  noTicketTitle: 'Aucune indemnité de gestion trouvée',
  noTicketDesc:
    "Malheureusement, nous n'avons trouvé aucune indemnité de gestion avec les informations saisies. Veuillez scanner à nouveau le QR code et vérifier le numéro d'UE.",
};
