import { Accordion } from 'components/Accordion/Accordion';
import { Input } from 'components/Input/Input';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useTranslation } from 'react-i18next';
import { appContext } from 'views/App';

const StyledForm = styled.form<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
  }
  .bottomText {
    @media ${device.laptop} {
      font-size: 16px;
    }
  }
  .plateNumberAccordion {
    justify-content: space-between;
    color: rgba(132, 132, 132, 1) !important;
    padding-top: 40px;
    cursor: pointer;
    p {
      color: rgba(132, 132, 132, 1) !important;
      font-weight: 600;
      font-size: 14px;
      @media ${device.laptop} {
        font-size: 24px;
      }
    }
    path {
      stroke: var(--sub--black);
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .alwaysLightInput {
    span {
      color: var(--black-always);
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    button {
      max-width: 125px;
    }
  }
`;
const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--sub--black);
  text-align: center;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  font-size: var(--text-l);
  @media ${device.laptop} {
    margin-bottom: 0;
    padding-left: 0;
    max-width: max-content !important;
    width: max-content;
  }
`;
const StyledFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 15px;
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    padding-bottom: 25px;
  }
`;
const StyledTitle = styled.div`
  padding-bottom: 15px;
  max-width: 652px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
`;
const StyledSpan = styled.span`
  color: var(--red);
`;
const StyledAccordion = styled.div`
  p {
    color: var(--black-always) !important;
  }
`;
export const CarDriverDetails = () => {
  const appContextStore = useContext(appContext);
  const carOwner = appContextStore?.carOwner;
  const setCarOwner = appContextStore?.setCarOwner;

  const methods = useForm();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSubmit, getValues, setValue } = methods;
  const { windowHeight } = useWindowHeight();
  const { t } = useTranslation();
  const onSubmit = () => {
    const fullAddress = `${getValues('streetName')} ${getValues(
      'streetNumber',
    )}, ${getValues('zipCode')} ${getValues('city')}`;
    const fullName = `${getValues('firstName')} ${getValues('lastName')}`;
    setCarOwner({
      fullAddress,
      fullName,
      contactDetails: getValues('email'),
      qrCodeId: params.qrCodeId,
      street: getValues('streetName'),
      streetNumber: getValues('streetNumber'),
      zipCode: getValues('zipCode'),
      city: getValues('city'),
    });
    navigate(`/check-details/${params.qrCodeId}`);
  };
  useEffect(() => {
    if (!carOwner) return;
    setValue('email', carOwner.contactDetails);
    const firstName = carOwner.fullName.split(' ')[0];
    setValue('firstName', firstName);
    const lastName = carOwner.fullName.split(' ')[1];
    setValue('lastName', lastName);
    setValue('streetName', carOwner?.street);
    setValue('streetNumber', carOwner?.streetNumber);
    setValue('zipCode', carOwner?.zipCode);
    setValue('city', carOwner?.city);
  }, [carOwner]);

  const convertNewlinesToBreaks = (text: string) => {
    return text.split('\n');
  };
  return (
    <ParkingTicketsLayout>
      <FormProvider {...methods}>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          height={`${windowHeight}px`}
        >
          <div>
            <StyledTitle>
              <Heading color="var(--black-always)">
                {t('cardriverDetailsTitle')}
              </Heading>
              <Paragraph dimension="s" color="var(--sub--black)">
                {process.env.REACT_APP_REGION === 'de'
                  ? `Wenn Sie für die Vertragsstrafe nicht verantwortlich sind, können Sie die Vertragsstrafe innert 2 Tagen auf die Fahrzeugführerin oder den Fahrzeugführer umschrieben lassen.`
                  : t('carDriverDetailsDescription')}
              </Paragraph>
            </StyledTitle>
            <StyledFormContent>
              <Input
                name="firstName"
                label={t('firstName')}
                mode="light"
                placeholder={t('lastNamePlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="lastName"
                label={t('lastName')}
                mode="light"
                placeholder={t('firstNamePlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="streetName"
                label={
                  process.env.REACT_APP_REGION === 'de'
                    ? 'Straße'
                    : t('streetName')
                }
                mode="light"
                placeholder={t('streetPlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="streetNumber"
                label={t('streetNumber')}
                mode="light"
                placeholder={t('streetNumberPlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="zipCode"
                label={t('zipCode')}
                mode="light"
                placeholder={t('zipCodePlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="city"
                label={t('city')}
                mode="light"
                placeholder={t('driverCityPlaceholder')}
                isRequired
                isWithRequiredMark
                inputClassName="alwaysLightInput"
              />
              <Input
                name="email"
                label={t('email')}
                mode="light"
                placeholder={t('emailPlaceholder')}
                isRequired
                inputClassName="alwaysLightInput"
              />
            </StyledFormContent>
            <Paragraph
              dimension="s"
              color="var(--sub--black)"
              className="bottomText"
            >
              <StyledSpan>*</StyledSpan> {t('carDriverInfo')}
            </Paragraph>
            <StyledAccordion>
              <Accordion
                title={t('legalInformation')}
                accordionClassName="plateNumberAccordion"
                elements={convertNewlinesToBreaks(
                  process.env.REACT_APP_REGION === 'de'
                    ? `Sollte Ihr Kontrollschild gesperrt sein, haben Sie 24 Stunden Zeit, uns Ihre Halterangaben über das Online-Formular oder per E-Mail an info@falschparker.io mitzuteilen. Andernfalls können zusätzliche Kosten für Sie entstehen.
                    Falls Sie eine Rechnung wünschen, geben Sie bitte Ihre Halterdaten über unseren QR-Code ein. Erfolgt keine Angabe zum Halter oder Lenker des Fahrzeugs, müssen wir die Halterdaten beim KBA kostenpflichtig anfordern. Die dadurch entstehenden Zusatzkosten werden Ihnen in Rechnung gestellt.Falschparken auf Privatgrund gemäß § 280 Abs. 1, § 286 BGB aus Abtretung gem. § 398 BGB`
                    : t('legalInformationElement'),
                )}
              ></Accordion>
            </StyledAccordion>
          </div>
          <StyledButtonContainer>
            <Button className="nextButton" type="submit">
              {t('next')}
            </Button>
            <StyledButton
              onClick={() => navigate(`/ticket-details/${params.qrCodeId}`)}
            >
              {t('back')}
            </StyledButton>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
    </ParkingTicketsLayout>
  );
};
