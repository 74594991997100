export const main = {
  onboardingTitle: 'Risolvi il tuo problema di parcheggio abusivo!',
  onboardingDescription:
    'Con Falsch-Parker puoi emettere indennità per gestione e rimuovere veicoli secondo la legge.',
  onboardingButton: 'Inizia ora',
  signinTitle: 'Accedi',
  requiredError: 'Questo campo è obbligatorio',
  emailLabel: 'E-mail',
  emailPlaceholder: 'Inserisci l’email',
  emailValidationError: 'La tua email è errata',
  firstNameLabel: 'Nome',
  firstNamePlaceholder: 'Inserisci il nome',
  lastNameLabel: 'Cognome',
  lastNamePlaceholder: 'Inserisci il cognome',
  phoneLabel: 'Numero di telefono',
  phonePlaceholder: 'Inserisci il numero di telefono',
  phoneValidationError: 'Numero di telefono troppo corto',
  password: 'Password',
  passwordPlaceholder: 'Inserisci la tua password',
  passwordValidationError: 'La password è troppo corta',
  confirmPassword: 'Conferma password',
  confirmPasswordPlaceholder: 'Conferma la password',
  confirmPasswordValidationError: 'Le password non coincidono',
  confirmNewPasswordValidationError: 'Le nuove password non coincidono',
  currentPasswordValidationError: 'La password attuale è errata',
  signinRememberMe: 'Ricordami',
  signinForgotPassword: 'Hai dimenticato la password?',
  signin: 'Accedi',
  socialMediaTitle: 'Oppure continua con',
  signUpLabel: 'Non hai un account?',
  signUp: 'Registrati',
  signUpTermAndConditions: 'Termini e condizioni',
  signUpButton: 'Crea account',
  signInLabel: 'Hai già un account?',
  referCodeTitle: 'Codice di riferimento',
  referCodePlaceholder: 'Se non hai un codice, lascia vuoto',
  doesntHaveCode: 'Hai già un account?',
  skip: 'Salta',
  referCodeConfirm: 'Conferma codice',
  otpTitle: 'Inserisci OTP',
  notReceivedCode: 'Non hai ricevuto il codice?',
  resend: 'Rinvia',
  otpConfirm: 'Conferma',
  continue: 'Continua',
  congratulations: 'Congratulazioni!',
  accountCreatedSuccesfully: 'Il tuo account è stato creato con successo',
  homeTitle: 'I nostri servizi',
  carTowing: 'Rimozione',
  carTowingSubtitle: 'Rimozione conforme alla legge',
  parkingTicket: 'Multa per parcheggio',
  parkingTicketSubtitle: 'Sanziona il parcheggio non autorizzato',
  companyName: 'Nome azienda',
  companyNamePlaceholder: 'Inserisci il nome dell’azienda',
  companyStreet: 'Via dell’azienda',
  companyStreetPlaceholder: 'Inserisci la via dell’azienda',
  companyStreetNumber: 'Numero civico dell’azienda',
  companyStreetNumberPlaceholder: 'Inserisci il numero civico',
  companyZipCode: 'CAP dell’azienda',
  companyZipCodePlaceholder: 'Inserisci il CAP dell’azienda',
  companyCity: 'Città dell’azienda',
  companyCityPlaceholder: 'Inserisci la città dell’azienda',
  companyAddress: 'Indirizzo',
  companyAddressPlaceholder: 'Inserisci l’indirizzo',
  userRoles: 'Ruoli utente',
  switchToUserProfile: 'Passa al profilo utente',
  preference: 'Preferenze',
  darkMode: 'Modalità scura',
  general: 'Gestione del parcheggio',
  rewards: 'Ricompense',
  referAFriend: 'Aggiungi collaboratore',
  becomeADriver: 'Diventa autista',
  defaultPayment: 'Pagamento predefinito',
  addNewLicenses: 'Aggiungi/rimuovi autorizzazione di parcheggio',
  addNewProperty: 'Gestisci i tuoi parcheggi',
  addPropertyButton: 'Aggiungi parcheggi',
  licenseTitle: 'Gestisci targhe',
  parkingNotExist: 'Devi prima aggiungere dei parcheggi',
  contact: 'Contatti',
  FAQ: 'FAQ',
  aboutUs: 'Chi siamo',
  helpSupport: 'Aiuto e supporto',
  termsCondition: 'Termini e condizioni',
  dataProtection: 'Informativa sulla privacy',
  dataProtectionTowShare: 'Informativa sulla privacy di TowShare Group GmbH',
  imprint: 'Impressum',
  logout: 'Disconnetti',
  editProfile: 'Menu principale',
  done: 'Salva',
  changeProfilePhoto: 'Cambia foto profilo',
  referalCode: 'Codice di riferimento',
  redeemDetails: 'Riscatta dettagli',
  add: 'Aggiungi',
  bankTransfer: 'Bonifico bancario',
  newBankDetails: 'Nuovo conto bancario',
  paypal: 'PayPal',
  newPaypalDetails: 'Nuovi dettagli PayPal',
  bankDetails: 'Dettagli bancari',
  save: 'Salva',
  beneficaryName: 'Beneficiario',
  beneficaryNamePlaceholder: 'Inserisci il nome del beneficiario',
  bankName: 'Nome banca',
  bankNamePlaceholder: 'Inserisci il nome della banca',
  accountNumber: 'Numero di conto',
  accountNumberPlaceholder: 'Inserisci il numero di conto',
  iban: 'IBAN',
  ibanPlaceholder: 'Inserisci l’IBAN',
  street: 'Via',
  streetPlaceholder: 'Inserisci la via',
  streetNumber: 'Numero civico',
  streetNumberPlaceholder: 'Inserisci il numero civico',
  zipCode: 'CAP',
  zipCodePlaceholder: 'Inserisci il CAP',
  city: 'Città',
  cityPlaceholder: 'Inserisci la città',
  successRedeemSubtitle: 'Hai aggiunto un conto bancario',
  successContractSubtitle: 'Il tuo parcheggio è stato aggiunto',
  rewardsHistory: 'Storico delle ricompense',
  history: 'Storico',
  sortBy: 'Ordina per',
  dateOfBirth: 'Data di nascita',
  dateOfBirthPlaceholder: 'Inserisci la tua data di nascita',
  license: 'Targa',
  govermentId: 'ID / Passaporto',
  requestForVerification: 'Richiesta di verifica',
  verificationRequested: 'Verifica richiesta',
  successDriverSubtitle:
    'Abbiamo ricevuto la tua richiesta per diventare autista di Falschparker. Ti notificheremo lo stato della tua candidatura entro 1-2 giorni lavorativi.',
  orders: 'Ordini di rimozione',
  badParkedCar: 'Rimozione',
  badParkedCarConfirmationPickup: 'Rimozione auto',
  badParkedCarTicketConfirmationPickup: 'Multa per parcheggio',
  licensePlate: 'Targa',
  licensePlatePlaceholder: 'Inserisci la targa',
  carModelName: 'Nome del modello d’auto',
  carModelNamePlaceholder: 'Inserisci il nome del modello d’auto',
  carPhoto: 'Foto del veicolo parcheggiato abusivamente',
  addPhoto: 'Aggiungi foto',
  addVideo: 'Aggiungi video',
  yourLocation: 'Posizione del veicolo parcheggiato abusivamente',
  findDriver: 'Trova un rimozionista',
  changeLocation: 'Cambia indirizzo',
  cancel: 'Annulla',
  cancelSecond: 'Annulla',
  findingTheDriver: 'Trovando un rimozionista...',
  cancelFindDriverAlert: 'Vuoi davvero annullare l’ordine?',
  cancelOrderAlert: 'Vuoi davvero annullare l’ordine?',
  confirmOrderAlert: 'Sei sicuro che tutti i dati inseriti siano corretti?',
  reward: 'Ricompensa',
  pickUpLocation: 'Punto di ritiro',
  ETA: 'Orario stimato di arrivo',
  towingDriverName: 'Nome del conducente del rimorchio',
  addressNotFound: 'Indirizzo non trovato. Per favore riprova',
  successOrderSubtitle:
    'Il tuo ordine di rimozione è stato inviato con successo.',
  loginError: 'Email o password errata',
  standardPayment: 'Pagamento predefinito',
  addParking: 'Aggiungi proprietà',
  unsuccessReportTitle: 'Gentile cliente',
  unsuccessReportSubtitle:
    'Per avviare un report di rimozione o una multa, aggiungi prima un parcheggio.',
  oldPassword: 'Vecchia password',
  newPassword: 'Nuova password',
  changePassword: 'Cambia password',
  confirmChangeSave: 'Le modifiche sono state salvate',
  orderDetails: 'Dettagli dell’ordine',
  carModel: 'Modello auto',
  dateAndTime: 'Data e ora',
  driveInformation: 'Informazioni conducente',
  customerInformation: 'Informazioni utente',
  ticket: 'Ticket',
  orderID: 'Stato dell’ordine',
  companyExecutive: 'Nome del conducente del rimorchio',
  confirmTicket: 'Il risarcimento è stato emesso con successo!',
  confirmTowingStatus:
    'Stiamo rimuovendo il veicolo parcheggiato abusivamente!',
  all: 'Tutti',
  new: 'Nuovo',
  accepted: 'Accettato',
  inProgress: 'In corso',
  towedAway: 'Rimosso',
  finished: 'Completato',
  finish: 'Completa',
  cancelled: 'Annullato',
  issued: 'Emesso',
  userNoReportsYet: 'Non hai ancora creato alcun report',
  driverNoReportsYet: 'Nessun incarico assegnato',
  propertyAndLicenses: 'Menu principale',
  addProperty: 'Aggiungi proprietà / parcheggio',
  newPropertyDetails: 'Nuovo parcheggio',
  addPropertyDetails: 'Aggiungi proprietà/parcheggio',
  addLicenses: 'Aggiungi veicoli autorizzati',
  newLicenseDetails: 'Aggiungi nuova targa',
  addLicenseDetails: 'Aggiungi targa',
  carModelPlaceholder: 'Inserisci il modello d’auto',
  propertyName: 'Nome proprietà / parcheggio (n.)',
  propertyNamePlaceholder: 'Aggiungi nome',
  address: 'Indirizzo',
  addressPlaceholder: 'Inserisci l’indirizzo',
  signAContract: 'Firma un contratto',
  contract: 'Contratto',
  contractName: 'Contratto di sessione',
  contractSubHeading:
    "L'app e i nostri servizi sono gratuiti per il committente in qualsiasi momento.",
  from: 'Data',
  inBetween: 'tra',
  clientCH: 'Committente',
  clientDE: 'Committente',
  contractor: 'Contraente',
  contractorAddress: 'TowShare Group GmbH\nOberdorfstrasse 15\n8001 Zurigo',
  contractorShortAddress: 'TowShare Group GmbH',
  and: 'e',
  contractSubtitle:
    "<strong>Oggetto del contratto:</strong> Tramite l'app FalschParker, gli utenti possono imporre un'indennità (multa) per parcheggio scorretto e/o far rimuovere i veicoli.",
  contractFirstPointTitleCH: 'Oggetto del contratto',
  contractFirstPointContentCH:
    "Il contraente si occupa dell'elaborazione e dell'invio delle indennità per violazioni di parcheggio e provvede alla rimozione dei veicoli in sosta irregolare.",
  contractSecondPointTitleCH: 'Indennità',
  contractSecondPointContentCH:
    "Per le violazioni di parcheggio viene richiesta un'indennità al trasgressore.",
  contractThirdPointTitleCH: 'Prestazioni del contraente',
  contractThirdPointContentCH:
    'Su richiesta del committente, il contraente rimuove i veicoli parcheggiati in modo irregolare. Questo include il traino, il trasporto sicuro e la corretta custodia dei veicoli.',
  contractFourthPointTitleCH: 'Incarico',
  contractFourthPointContentCH:
    "L'incarico avviene tramite l'app FalschParker o, in casi particolari, telefonicamente.",
  contractFifthPointTitleCH: 'Dovere di diligenza',
  contractFifthPointContentCH:
    "Il contraente si impegna a fornire i servizi con attenzione e competenza secondo lo stato dell'arte e risponde del comportamento dei suoi dipendenti.",
  contractSixthPointTitleCH: 'Dichiarazione di cessione',
  contractSixthPointContentCH:
    "Il committente cede tutti i diritti verso i trasgressori al contraente, compresi i diritti per l'imposizione delle indennità e gli incarichi di rimozione. In caso di ripetute violazioni, il contraente ha il diritto di presentare una denuncia ai sensi dell'art. 258 CPC.",
  contractSeventhPointTitleCH: 'Informazioni veritiere sul parcheggio',
  contractSeventhPointContentCH:
    'Il committente conferma che per il parcheggio esiste un contratto di locazione valido o che il parcheggio è di sua proprietà.',
  contractEighthPointTitleCH: 'Supplemento per stazioni di servizio',
  contractEighthPointContentCH:
    "Con l'app FalschParker, i gestori di stazioni di servizio possono segnalare casi di mancato pagamento del carburante e di fuga senza pagare. Il contraente si occupa dell'incasso e trasferisce al gestore della stazione di servizio l'importo dovuto il giorno successivo al pagamento. Al trasgressore viene imposta un'indennità di almeno 20,00 CHF.",
  contractNinthPointTitleCH: 'Riservatezza',
  contractNinthPointContentCH:
    "Entrambe le parti si impegnano alla riservatezza di tutte le informazioni ottenute nell'ambito del contratto.",
  contractTenthPointTitleCH: 'Durata e risoluzione',
  contractTenthPointContentCH:
    'Il contratto entra in vigore con la firma e ha durata indeterminata. Può essere risolto in qualsiasi momento per iscritto.',
  contractEleventhPointTitleCH: 'Modifiche',
  contractEleventhPointContentCH:
    'Le modifiche al contratto richiedono il consenso scritto di entrambe le parti.',
  contract12PointTitleCH: 'Legge applicabile e foro competente',
  contract12PointContentCH:
    'Il contratto è regolato dalla legge svizzera, il foro competente è Zurigo.',
  schlieren: 'Schlieren',
  zurich: 'Zurigo',
  refCodeNotFound: 'Codice di riferimento non trovato',
  notifications: 'Notifiche',
  contractSignature: 'Firma del contratto',
  inputSignature: 'Inserire firma',
  signatureInfo: 'Per completare l’incarico, è necessario firmare qui',
  signature: 'Firma',
  termAndConditions: 'Accetto la collaborazione',
  orderNumber: 'Numero dell’ordine',
  confirmOrderArrival: 'Sei sicuro di essere arrivato a destinazione?',
  confirmCarPicked: 'Sei sicuro di aver trovato l’auto corretta?',
  confirmCarParkArrival: 'Sei sicuro di essere arrivato?',
  cancelCarParkArrival: 'Sei sicuro di voler annullare l’incarico?',
  uploadVideoAndPhotos: 'Carica video e foto',
  uploadVideoAndPhotosInfo:
    'Per verificare, carica un video dell’auto, seguito da un massimo di 4 immagini',
  carVideo: 'Video auto parcheggiata illegalmente',
  carkParkLocation: 'Luogo di consegna del veicolo',
  carFalschOffice: 'Ufficio Auto Parcheggiata Illegalmente',
  successDriverOrderSubtitle: 'Hai completato con successo l’incarico!',
  registerPlateCheck: 'Controllo targa',
  registerPlateCheckSubtitle:
    'Verifica se l’auto è autorizzata a parcheggiare all’indirizzo',
  parkedCarRegisterPlateCheck: 'Controlla targa',
  formPopupValidation: 'Sei sicuro che tutti i dati inseriti siano corretti?',
  registerPlate: 'Targa',
  isAllowedToParking: 'è autorizzato a parcheggiare',
  isNotAllowedToParking: 'non è autorizzato a parcheggiare',
  startTicket: 'Avvia Ticket',
  startTowing: 'Inizia Rimozione',
  successLicenseSubtitle: 'Targa aggiunta con successo',
  editLicense: 'Modifica Targa',
  editProperty: 'Modifica Parcheggio / Proprietà',
  changeSave: 'Le modifiche sono state salvate',
  deleteLicense: 'Elimina Targa',
  deleteLicenceConfirm: 'Sei sicuro di voler eliminare la targa registrata?',
  switchToDriverProfile: 'Passa al Profilo Autista',
  superAdmin: 'Super Admin',
  user: 'Utente',
  driver: 'Autista',
  customerName: 'Nome utente',
  customerNumber: 'Numero cliente',
  orderPlacement: 'Ora di invio ordine',
  ticketOrderPlacement: 'Ora della violazione di parcheggio',
  orderAcceptence: 'Accettazione dell’ordine',
  arrivalTime: 'Ora di arrivo',
  timeOfPickup: 'Ora di ritiro',
  timeOfExecution: 'Ora di consegna',
  verifyCarOwner: 'Verifica proprietario dell’auto',
  fullName: 'Nome completo',
  fullNamePlaceholder: 'Inserisci nome completo',
  contactDetails: 'Dettagli di contatto',
  emailAddress: 'E-mail',
  fullAddress: 'Indirizzo completo',
  driverId: 'Patente di guida & ID (fronte)',
  carRegistration: 'Certificato di registrazione del veicolo',
  recent: 'Più recenti',
  older: 'Più vecchi',
  licenseFront: 'Patente & ID (fronte)',
  licenseBack: 'Patente & ID (retro)',
  govermentIdFront: 'Certificato di registrazione veicolo',
  govermentIdBack: 'Passaporto',
  helpAndSupport: 'Assistenza e supporto',
  falschParkerHelp: 'Assistenza Falsh Parker',
  helpContent:
    'Il supporto è disponibile 24/7 via telefono o WhatsApp. Rispondiamo direttamente e senza attese a domande sul funzionamento dell’app o su azioni specifiche.',
  allowedCarPopup:
    'Questa vettura può parcheggiare e non può essere rimossa o multata.',
  back: 'Indietro',
  call: 'Chiama',
  message: 'Messaggio',
  confirm: 'conferma',
  confirmShort: 'SÌ',
  confirmNext: 'Continua',
  driverName: 'Nome autista',
  delete: 'elimina',
  retake: 'riprendi',
  language: 'Lingua',
  defaultLanguage: 'Lingua predefinita',
  de: 'Tedesco',
  en: 'Inglese',
  it: 'Italiano',
  fr: 'Francese',
  oldPasswordPlaceholder: 'Inserisci vecchia password',
  newPasswordPlaceholder: 'Inserisci nuova password',
  destinationArrived: 'Arrivato a destinazione',
  userName: 'Nome utente',
  downloadReceipt: 'Scarica ricevuta',
  imptint: 'Impressum',
  imptintTitle: 'Impressum',
  driverAcceptError: 'Non puoi accettare più di un rapporto contemporaneamente',
  referAndPromote: 'Aggiungi',
  referToPromote: 'Aggiungi un membro dello staff',
  orderStatus: 'Stato dell’ordine',
  driverAcceptConfirm: 'Sei sicuro di voler accettare l’ordine?',
  driverDectineConfirm: 'Sei sicuro di voler rifiutare il rapporto?',
  backendErrorPartOne:
    'Abbiamo riscontrato un errore. Per favore contatta il supporto di',
  backendErrorPartTwo: '',
  noNotification: 'Non ci sono notifiche disponibili',
  closeReportContent: 'Sei sicuro di voler annullare l’incarico?',
  executeOrder: 'Inizia restituzione del veicolo',
  pleaseAddSignature: 'Aggiungi firma per favore',
  next: 'Continua',
  accept: 'Accetta',
  decline: 'Annulla',
  arrived: 'Arrivato',
  successCarOwenrContractSubtitle:
    'Grazie per aver completato il rapporto di rimozione',
  minute: 'Minuto',
  minutes: 'Minuti',
  turnOnGPS: 'Attiva GPS',
  gpsPopupContent:
    'Caro utente. Per favore attiva il GPS del dispositivo, altrimenti non potrai utilizzare l’app',
  gpsErrorPopupContent:
    'Gentile utente. Si prega di consentire al browser di utilizzare i servizi di localizzazione. È possibile farlo nelle impostazioni del browser',
  404: 'Ops! Sembra che la pagina che volevi accedere non sia disponibile',
  searchPlaceholder: 'Cerca targa...',
  resetPasswordTitle: 'E-mail per ripristino password',
  resetPassword: 'Invia password',
  resetPasswordError: 'L’email inserita non è collegata a nessun account',
  resetPasswordSuccess:
    'Dovresti ricevere un’email con le istruzioni per reimpostare la password',
  userExist: 'Questa email è già utilizzata per un altro account. Usa un’altra',
  yes: 'Sì',
  no: 'No',
  pinWrongParkedLocation: 'Posizione errata parcheggio',
  pinTowingTruckLocation: 'Posizione carro attrezzi',
  pinParkingSpaceLocation: 'Indirizzo parcheggio',
  googleMapsPopupContent:
    'Vuoi utilizzare Google Maps per la navigazione verso la destinazione?',
  screenAlwaysOn: 'Per favore, lascia lo schermo sempre acceso',
  tooBigFile:
    'Il file video è troppo grande. Realizza un nuovo video più breve.',
  uploadVideoAndPhotos360: 'Video e foto a 360 gradi',
  uploadVideoAndPhotos360Subtitle:
    "Per verificare, carica un video dell'auto seguito da 3 immagini",
  twentyCashback: '20 CHF CASHBACK',
  fiftyCashback: '50 CHF CASHBACK',
  reportNumber: "Numero dell'ordine",
  tenChf: '10 CHF CREDITO',
  referAndGet: 'CONSIGLIA E OTTIENI +',
  towingActiveHistoryTitle: 'Nuovi ordini',
  towingDeactiveHistoryTitle: 'Ordini di rimozione',
  ticketHistoryTitle: 'Multa di parcheggio',
  allHistoryTitle: 'Storico dei report',
  please: 'Per favore',
  mark: 'segna',
  rentalAgreementPhotos: 'Foto del contratto di noleggio',
  addRentalProperty: 'Aggiungi proprietà in affitto',
  newRentalPropertyDetails: 'Nuova proprietà (dettagli)',
  addRentalPropertyDetails: 'Aggiungi dettagli della proprietà in affitto',
  uploadPhotos: 'Carica foto',
  uploadPhotosInfo:
    'Per la verifica, carica le immagini del contratto di noleggio',
  invalidZipCodePopup:
    'Il servizio di rimozione non è attualmente disponibile nella tua zona. Stiamo lavorando duramente per renderlo disponibile presto.',
  paymentMethod: 'Metodo di pagamento',
  invoice: 'Fattura',
  paymentOnTheSpotCash: 'Pagamento in contanti sul posto',
  paymentOnTheSpotCard: 'Pagamento con carta sul posto',
  freeParking: 'Parcheggio Drop-Off',
  inProgressButton: 'Ordine attuale',
  inProgressSubtitle: 'Verifica il rapporto in corso',
  policeParkingAddress: 'Indirizzo del parcheggio di destinazione',
  acceptedReportError: 'Un altro conducente ha già accettato questo rapporto',
  clientName: 'Nome azienda',
  selectParkingArea: 'Parcheggi clienti nelle vicinanze',
  autoGPSOff:
    'Gentile utente, attiva il GPS sul dispositivo per utilizzare la localizzazione automatica.',
  paymentStatus: 'Stato del pagamento',
  cashbackStatus: 'Stato Cashback',
  open: 'Aperto',
  paid: 'Pagato',
  notPaid: 'Non pagato',
  paidOnTheSpot: 'Pagato nel negozio',
  paidOnSpotButton: 'Cliente ha pagato nel negozio',
  pending: 'In attesa',
  clients: 'Clienti',
  clientNumber: 'Numero cliente',
  contactNumber: 'Numero di contatto',
  clientFullName: 'Nome del cliente',
  cashbackOn: 'Cashback attivo',
  cashbackOff: 'Cashback disattivo',
  clientsSearchPlaceholder: 'Cerca clienti...',
  wrongAddress:
    "Inserisci un indirizzo corretto. L'indirizzo non è stato trovato su Google Maps",
  clientReports: 'Ordini completati clienti',
  clientReportsTitle: 'Ordini completati del cliente',
  clientReportsSearchPlaceholder: 'Cerca...',
  downloadContract: 'Scarica la cessione',
  cashback: 'Cashback',
  claimed: 'Richiesto',
  bankAccount: 'Conto bancario',
  newBank: 'Aggiungi un nuovo conto bancario',
  downloadAppPopup: "Scarica l'app FalschParker",
  tap: 'Tocca per',
  tapInstruction: 'aggiungi alla schermata Home',
  sign: 'Firma',
  bill: 'Fattura',
  gasStationButtonTitle: 'Fuga di carburante',
  gasStationButtonSubtitle: 'Rifornimento senza pagamento',
  photo: 'Foto del veicolo',
  video: 'Video (max. 30 sec)',
  controlPlate: 'Targa',
  controlPlatePlaceholder: 'Inserisci la targa',
  billPhoto: 'Foto della ricevuta del carburante',
  gasStationAddressTitle: 'Indirizzo stazione di servizio',
  price: 'Prezzo',
  priceGasStation: 'Importo rifornimento',
  billTime: 'Orario rifornimento',
  cancelledTime: 'Tempo cancellato',
  gasStationSuccessReport:
    'La segnalazione del reato di carburante è stata completata con successo',
  bankAccountName: 'Nome del titolare del conto',
  bankAccountNameePlaceholder: 'Inserisci il nome del titolare del conto',
  gasStationOn: 'Fuga di carburante attiva',
  gasStationOff: 'Fuga di carburante inattiva',
  confirmReportDelete: "Sei sicuro di voler eliminare l'ordine?",
  deleteReport: 'Elimina ordine',
  gasStationSelect: 'Seleziona stazione di servizio',
  activateParkingTitle: 'Registra la tua targa per registrarti come visitatore',
  activateParkingPlaceholder: 'Inserisci la targa',
  confirmActivateParking: 'Salva',
  confirmActivatedParking: 'Targa registrata per 24 ore',
  cancelSelectParking: 'Annulla',
  ticketId: 'ID multa di parcheggio',
  ticketIdPlaceholder: 'Scansiona il codice QR',
  notes: 'Nota',
  notesPlaceholder: 'Opzionale',
  userOrderAddress: 'Seleziona posizione',
  parkingNotes: 'Descrivi la posizione esatta del tuo parcheggio',
  uploadParkingPhotos: 'Scatta una foto del tuo parcheggio',
  parkingTitle: 'Nome della posizione del parcheggio',
  reportType: 'Tipo di rapporto',
  towingTickets: 'Ordini / Multa di parcheggio',
  priceBill: 'Importo del rifornimento',
  priceBillPlaceholder: "Inserisci l'importo del rifornimento",
  priceCarOwner: "Prezzo dell'ordine di rimozione",
  priceCarOwnerPlaceholder: "Inserisci il prezzo dell'ordine di rimozione",
  plateValidation: 'Solo lettere e numeri',
  selectUserType: 'Seleziona il tipo di utente:',
  companyClient: 'Cliente aziendale',
  userClient: 'Cliente privato',
  pleaseSelectParking: 'Seleziona una zona di parcheggio',
  pleaseChangeAddress:
    'Inserisci un altro indirizzo e seleziona una zona di parcheggio',
  appVersion: "Versione dell'app",
  confrimPayOnSpot: 'Confermare il pagamento sul posto?',
  selectTheLocation: 'Seleziona posizione',
  qrScanner: 'Scanner QR',
  fuelFraud: 'Fuga di carburante',
  ticketSection: 'Multa di parcheggio',
  pricePlaceholder: 'Inserisci il prezzo',
  cashbackPlaceholder: 'Inserisci il cashback',
  gasStationSection: 'Fuga di carburante',
  towingSection: 'Rimozione',
  accidentalEvasion: 'Era nel negozio',
  intensionalEvasion: 'Non era nel negozio',
  accidentalEvasionCashback: 'Era nel negozio Cashback',
  intensionalEvasionCashback: 'Non era nel negozio Cashback',
  emptyRun: 'Corsa a vuoto',
  cameraAlert:
    "La fotocamera è bloccata o non accessibile. Consenti l'accesso alla fotocamera nelle impostazioni del browser e ricarica.",
  loadingMediaPopup: "I file sono in caricamento, non chiudere l'app",
  objectionRaiseEmailPlaceholder: 'La tua e-mail',
  notePlaceholder: 'Descrizione del ricorso',
  driverCityPlaceholder: 'Inserisci il luogo',
  qrCodeId: 'ID QR-Multa',
  cancelButton: 'Annulla',
  sharingError: 'Il sistema non supporta la condivisione di questo file',
  startScanner: 'Avvia scanner',
  downloadQrCode: 'Scarica codice QR',
  generateQrCode: 'Mostra codice QR',
  pleaseAddPrice: 'Per favore, aggiungi il prezzo',
  emptyRunCashback: 'Corsa a vuoto Cashback',
};
