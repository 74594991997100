/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Layout } from 'components/Layout/Layout';
import { Signature } from 'components/Signature/Signature';
import { useAccountType } from 'hooks/useAccountType';
import { CREATE_PARKING_ZONE } from 'graphql/mutations/createParkingZone';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CREATE_CAR_OWNER } from 'graphql/mutations/createCarOwner';
import { Popup } from 'components/Popup/Popup';
import { useGetLocation } from 'hooks/useGetLocation';
import { CREATE_REPORT_LOG } from 'graphql/mutations/createReportLog';
import { from, useLazyQuery, useMutation } from '@apollo/client';
import { REPORT } from 'graphql/queries/report';
import { TermPopup } from 'components/TermPopup/TermPopup';
import { getPosition } from 'utils/getPosition';
import { Input } from 'components/Input/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { CREATE_CAR_OWNER_PRESIGNED_REQUEST } from 'graphql/mutations/createCarOwnerPresignedRequest';
import { FileData } from 'types/general';
import * as Comlink from 'comlink';
import { useUploadingFileProgress } from 'hooks/useUploadingFileProgress';
import { ZURICH_POSITION } from 'data/position';
import { ContractSignatureButtonContainer } from './ContractSignatureButtonContainer/ContractSignatureButtonContainer';
import { ContractSignatureHeading } from './ContractSignatureHeading/ContractSignatureHeading';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';
import { useProvideAuth } from 'hooks/useProvideAuth';
import { useUploadImageForParkingZone } from 'api/useUploadImageForParkingZone';
import { useUploadImageForCarOwnerFiles } from 'api/useUploadCarOwnerImageFiles';

const StyledContainer = styled.div<{ justifyContent: string }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  height: 100%;
  min-height: 670px;
`;

const StyledSignature = styled(({ className }) => (
  <Signature className={className} />
))`
  margin-bottom: 17px;
`;

export const ContractSignature = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setUserData = appContextStore?.setUserData;
  const parkingData = appContextStore?.parkingData;
  const carOwner = appContextStore?.carOwner;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const { uploadSignature } = useUploadImageForParkingZone();
  const { uploadCarOwnerImageFiles } = useUploadImageForCarOwnerFiles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean | null>(
    null,
  );
  const [isTermPopupOpened, setIsTermPopupOpened] = useState<boolean>(false);
  const isSignature = Boolean(parkingData?.signature);
  const { mutate } = useUploadMutation();
  const { isThisAccountType } = useAccountType('driver');
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const { position, getLocation } = useGetLocation();
  const { hash } = useParams();
  const [reportId, setReportId] = useState<string | null>(null);
  const isVerifyCarOwnerPage = location.pathname.includes('verify-signature');
  const methods = useForm();
  const { getValues, setError, watch, clearErrors } = methods;
  const [report, { data: reportData }] = useLazyQuery(REPORT);
  const { getAuthHeaders } = useProvideAuth();
  const [createReportLog] = useMutation(CREATE_REPORT_LOG, {
    onCompleted: () => {
      setIsLoading(false);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsLoading(false);
      setIsError(true);
      throw new Error(error.message);
    },
  });

  const handleBtnClick = () => {
    if (isSignature && isCheckboxChecked) {
      if (isThisAccountType && !isVerifyCarOwnerPage) {
        navigate('/order-address');
      } else {
        handleNextClick();
      }
    }
    if (!isSignature) {
      setIsPopupOpened(true);
    }
    if (!isCheckboxChecked) {
      setIsCheckboxChecked(false);
      setIsTermPopupOpened(true);
    }
  };

  const handleNextClick = async () => {
    if (
      carOwner?.paymentType != 1 &&
      !getValues('price') &&
      isVerifyCarOwnerPage
    ) {
      setError('price', { message: t('pleaseAddPrice'), type: 'required' });
    } else {
      if (isVerifyCarOwnerPage) {
        setIsLoading(true);
        mutate(CREATE_CAR_OWNER, {
          variables: {
            ...carOwner,
            price: getValues('price') ? Number(getValues('price')) : null,
            signature: parkingData?.signature,
          },
        })
          .then(async (result) => {
            console.log(result);
            if (result) {
              createReportLog({
                variables: {
                  id: reportId,
                  information: 'Report Admin Finish',
                  latitude: position?.latitude || ZURICH_POSITION.latitude,
                  longitude: position?.longitude || ZURICH_POSITION.longitude,
                },
              });
              if (carOwner?.driverIdPhoto || carOwner?.platePhoto) {
                const response = await uploadCarOwnerImageFiles({
                  hash: result.data.createCarOwner.hash,
                  driverIdPhoto: carOwner?.driverIdPhoto,
                  platePhoto: carOwner?.platePhoto,
                });
                if (response.ok) {
                  setIsLoading(false);
                  navigate('/car-owner-contract-success');
                }
              } else {
                setIsLoading(false);
                navigate('/car-owner-contract-success');
              }
            }
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
            setIsLoading(false);
            setIsError(true);
            throw new Error(error);
          });
      } else {
        setIsLoading(true);
        const fullAddress = `${parkingData.streetName} ${parkingData.streetNumber}, ${parkingData.zipCode} ${parkingData.city}`;
        const position = await getPosition(fullAddress);
        mutate(CREATE_PARKING_ZONE, {
          variables: {
            ...parkingData,
            signature: parkingData?.signature,
            fullAddress: `${parkingData.streetName} ${parkingData.streetNumber}, ${parkingData.zipCode} ${parkingData.city}`,
            type: parkingData?.type,
            latitude: position?.lat || ZURICH_POSITION.latitude,
            longitude: position?.lng || ZURICH_POSITION.longitude,
          },
        })
          .then(async (result) => {
            if (!result.data) return;
            await uploadSignature({
              id: result.data.createParkingZone.id,
              signatureImage: parkingData.signature,
            });
            setIsLoading(false);
            navigate('/contract-success');
            setUserData({ ...userData, verified: true });
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
            setIsError(true);
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (!hash) return;
    report({ variables: { hash } });
  }, [hash]);

  useEffect(() => {
    if (!reportData) return;
    setReportId(reportData.report.id);
  }, [reportData]);

  useEffect(() => {
    getLocation();
  }, []);

  const handleTermConfirm = () => {
    setIsCheckboxChecked(true);
    setIsTermPopupOpened(false);
  };

  useEffect(() => {
    if (isVerifyCarOwnerPage) setIsCheckboxChecked(true);
  }, [isVerifyCarOwnerPage]);

  useEffect(() => {
    clearErrors();
  }, [watch('price')]);

  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('contractSignature')}
      outsideElements={
        <div>
          <Popup
            isPopupOpened={isPopupOpened}
            onDecline={() => setIsPopupOpened(false)}
            content={t('pleaseAddSignature')}
            confirmText="ok"
            type="info"
          />
          <TermPopup
            isTermPopupOpened={isTermPopupOpened}
            handleTermConfirm={handleTermConfirm}
            handleTermDecline={() => setIsTermPopupOpened(false)}
          />
        </div>
      }
    >
      <StyledContainer
        justifyContent={isVerifyCarOwnerPage ? 'start' : 'space-space-between'}
      >
        <div>
          <ContractSignatureHeading />
          <FormProvider {...methods}>
            {carOwner?.paymentType != 1 && isVerifyCarOwnerPage && (
              <Input
                name="price"
                label={t('priceCarOwner')}
                placeholder={t('priceCarOwnerPlaceholder')}
                isRequired
                requiredErrorMessage={t('requiredError')}
                type="number"
                step="0.01"
                bottomMarting="10"
                mode="light"
              />
            )}
          </FormProvider>
          <StyledSignature />
          {!isVerifyCarOwnerPage && (
            <Checkbox
              mode="dark"
              anchor="/agb"
              text={t('termsCondition')}
              onToggle={() => setIsCheckboxChecked((prev) => !prev)}
              error={isCheckboxChecked === false}
              isChecked={isCheckboxChecked}
            />
          )}
        </div>
        <ContractSignatureButtonContainer
          handleNextClick={handleBtnClick}
          isVerifyCarOwnerPage={isVerifyCarOwnerPage}
        />
      </StyledContainer>
    </Layout>
  );
};
