export const dataProtection = {
  dataProtectionContent: `I. Einführung
Wenn du die Falschparker App nutzt, vertraust du uns deine personenbezogenen Daten an. Wir verpflichten uns, dieses Vertrauen zu wahren. Daher möchten wir dir zunächst helfen, unsere Datenschutzpraktiken zu verstehen.

In dieser Datenschutzerklärung beschreiben wir, welche personenbezogenen Daten („Daten“) wir erheben, wie diese genutzt und weitergegeben werden und welche Optionen du im Hinblick auf diese Daten hast. Wir empfehlen dir, diesen Text zusammen mit unserem Überblick über den Datenschutz zu lesen, der die Eckpunkte unserer Datenschutzpraktiken erläutert.

II. Übersicht
A. Geltungsbereich
Diese Datenschutzerklärung gilt für die Nutzer der Services von TowShare Group GmbH in der Schweiz, einschließlich der Nutzer von Falschparker App, Websites, Funktionen oder anderer Services.

B. Datenverantwortliche und Datentransfer
TowShare Group GmbH (Oberdorfstrasse 15, 8001 Zürich, Schweiz) ist der Verantwortliche für die Daten, die in Zusammenhang mit der Nutzung der Services von TowShare Group GmbH in der Schweiz erhoben werden.

TowShare Group GmbH betreibt und verarbeitet personenbezogene Daten in der Schweiz. Egal, wo und wann Du dich befindest – Ihre Daten sind immer mit dabei, sodass wir dir eine reibungslose Erfahrung bieten können. Außerdem respektieren wir deine Datenschutzrechte – unabhängig davon, ob du dich in der Schweiz oder anderswo befindest.

Um überall dort, wo du unsere Dienste nutzt, einen gleichwertigen Datenschutz zu gewährleisten, hat TowShare Group GmbH in der Schweiz die folgenden Massnahmen eingeführt:

1. Richtlinien und Verfahren, um den Zugriff auf und die Verarbeitung von personenbezogene(n) Daten für definierte Zwecke einzuschränken;
2. spezifische Schulungen des für die Verwaltung personenbezogener Daten zuständigen Personals;
3. zuverlässiger Schutz der Daten, während sie zwischen der Falschparker App und unseren Servern übertragen werden und wenn die Daten auf unseren Servern verarbeitet werden. Dies umfasst die Verschlüsselung und die Verwendung von pseudonymisierten Daten, um die vollständige Identität der Nutzer vor unbefugter Offenlegung zu schützen.

Der Einfachheit halber kann die Ausübung deiner Datenschutzrechte über einen der oben genannten Kanäle an die TowShare Group GmbH weitergeleitet werden und wird im Namen des jeweiligen Verantwortlichen zentral abgewickelt. Fragen, Kommentare und Beschwerden in Bezug auf die Datennutzung von TowShare Group GmbH können per Email an info@towshare.ch und info@falsch-parker.ch eingereicht werden. Du kannst diese Email auch verwenden, um eine Frage an den Datenschutzbeauftragten von TowShare Group GmbH zu richten.


Darüber hinaus werden genaue Standortdaten, die beim aufgeben von Umtriebsentschädigungen & Abschleppaufträge vom Gerät des Kunden (User) erfasst werden mit dem Konto des Abschleppfahrer verknüpft, auch wenn der Nutzer / User / Kunde die Erfassung von genauen Standortdaten über sein Gerät nicht aktiviert hat. Diese Daten werden für Zwecke wie die Erstellung von Belegen, den Kundensupport, die Betrugserkennung, Versicherungsfragen und Rechtsstreitigkeiten verwendet.

3. Daten aus anderen Quellen. Dies umfasst:

Nutzer, die an unseren Empfehlungsprogrammen teilnehmen. Wenn beispielsweise ein Nutzer eine andere Person empfiehlt, erhalten wir die Daten der empfohlenen Person von diesem Nutzer.
Falschparker App Kontoinhaber, die Services für oder im Auftrag anderer Nutzer (wie Mieter, Mitarbeiter, Freunde oder Familienmitglieder) anfordern oder die es anderen Nutzern ermöglichen, Services über ihre Geschäftskonten anzufordern oder zu erhalten.Nutzer oder andere Dritte, die Informationen in Verbindung mit Forderungen oder Streitigkeiten bereitstellen.

Anbieter, die uns dabei helfen, die Identität der Nutzer, ihre Hintergrundinformationen und ihre Arbeitseignung zu verifizieren, oder die Nutzer im Zusammenhang mit Sanktionen, Geldwäschebekämpfung oder Kundenwissensanforderungen überprüfen. 

Firmenname: TowShare Group GmbH
Straße: Oberdorfstrasse 15
Postleitzahl: 8001
Stadt: Zürich
Land: Schweiz

B. Wie wir personenbezogene Daten verwenden
Falschparker nutzt Daten, um zuverlässige und professionelle Abschleppservice Dienstleistungen sowie andere Produkte und Services zu ermöglichen. Wir verwenden diese Daten auch:
zur Förderung der Sicherheit unserer Nutzer und Services
für den Kundensupport  
zur Forschung und Entwicklung  
zur Ermöglichung der Kommunikation zwischen Nutzern 
für Marketing und Werbung
um Nicht-Marketing-Mitteilungen an Nutzer zu senden
in Verbindung mit Gerichtsverfahren

Wir nutzen die von uns erhobenen Daten wie folgt:
1. Um unsere Services bereitzustellen. Die Falschparker App verwendet Daten, um seine Services bereitzustellen, zu personalisieren, zu pflegen und zu verbessern.

Dies umfasst die Verwendung von Daten für folgende Zwecke:
Das Erstellen/Aktualisieren von Konten
Um Umtriebsentschädigungen, Abschleppservice / Abschleppdienste zu ermöglichen, wie:
die Nutzung von Standortdaten, um die Abholungen von Falschparker zu navigieren, die voraussichtliche Ankunftszeit zu berechnen und den Fortschritt von Abschleppaufträgen zu verfolgen.
Funktionen, die das Teilen von Daten beinhalten, wie das Teilen des Vor-/ Nachname und Telefonnummer des Abschleppfahrer, um den Abschleppauftrag zu erleichtern, das Nutzerprofil und die Abschleppdetails für das Teilen der voraussichtlichen Ankunftszeit sowie Nutzerbewertungen.
Das Suchen passender Abschleppfahrer für Nutzer, die Abschleppservices bestellen. Abschleppfahrer können anhand ihrer Verfügbarkeit, ihres Standorts/ihrer Entfernung und anderer Faktoren wie der Wahrscheinlichkeit, einen Auftrag anzunehmen, sowie anhand ihres früheren Verhaltens oder ihrer Präferenzen zugewiesen werden. 
Das Zurverfügungstellen von Updates der Falschparker App
Durchführen der erforderlichen Vorgänge zur Aufrechterhaltung unserer Services, einschließlich der Behebung von Softwarefehlern und Betriebsproblemen.

Falschparker führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern erforderlich sind, mit den Nutzern vereinbar sind oder weil sie zur Wahrung der berechtigten Interessen von Falschparker und seinen Nutzern erforderlich sind.
2. Sicherheit und Schutz. Wir nutzen Daten, um die Sicherheit und Integrität unserer Services und Nutzer zu gewährleisten. Dazu gehören:
Die Verifizierung der Identität der User beim erstellen des Kontos. Dies geschieht u. a. in Form eines Zessionsvertrag und oder Mietvertrag, die gewährleisten, dass unsere Services nicht von User genutzt werden, die sich als jemand anderes ausgeben und somit Sicherheitsrisiko darstellen.

Darüber hinaus können wir Technologien zur Gesichtserkennung verwenden, um den Anmeldeprozess zu erleichtern und das unbefugte User verbeitet unseren Service zu nutzen.

3. Kunden-Support. Falschparker verwendet die erfassten Informationen (einschließlich Anrufaufzeichnungen), um den Kundensupport bereitzustellen, einschließlich der Untersuchung und Bearbeitung von Anliegen der Nutzer und der Überwachung und Verbesserung unserer Reaktionen und Prozesse im Kundensupport.

Falschparker führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern oder zum Zwecke des berechtigten Interesses von Falschparker an der Überwachung und Verbesserung seiner Kundenserviceleistungen erforderlich sind.
4. Forschung und Entwicklung. Wir nutzen personenbezogene Daten zum Testen, Erforschen, Analysieren sowie für die Produktentwicklung und maschinelles Lernen, um die Nutzererfahrung zu verbessern. Dies hilft uns dabei, unsere Services angenehmer und benutzerfreundlicher zu gestalten, die Sicherheit unserer Services zu verbessern und neue Services und Funktionen zu entwickeln.

Falschparker führt die oben genannten Aktivitäten mit der Begründung aus, dass sie für die berechtigten Interessen von TowShare Group GmbH an der Verbesserung und Entwicklung neuer Services und Funktionen erforderlich sind.
5. Ermöglichen der Kommunikation zwischen User & Abschleppdienste. Ein Abschleppfahrer kann z. B. einem User eine Textnachricht schicken oder ihn anrufen, um den Abholort des abzuschleppenden Falschparkerzu bestätigen.

TowShare Group GmbH  führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern erforderlich sind.
6. Marketing und Werbung. TowShare Group GmbH verwendet Daten, um seine Services und diejenigen von Partnern von Falschparker zu vermarkten. Dazu gehört möglicherweise:
Das Versenden von E-Mails, Textnachrichten, Push-Benachrichtigungen und In-App-Nachrichten oder anderen Mitteilungen zu Marketing- oder Werbezwecken für Produkte, Services, Funktionen, Angebote, Aktionen, Verlosungen, Nachrichten und Veranstaltungen von TowShare Group GmbH..

Dies kann die Verwendung von Standort-, Auftrags- und Bestellverlaufs- und Nutzungsdaten zum Senden von Marketing-Mitteilungen umfassen, die auf der Grundlage der beobachteten oder abgeleiteten Interessen und Merkmale der Nutzer personalisiert werden. 
Das Anzeigen von Falschparker Werbeanzeigen in Apps oder Websites von Drittanbietern. Dies umfasst die Verwendung des Standort-, Auftrags- und Bestellverlaufs und der Nutzungsdaten sowie das Teilen von Hash-E-Mail-Adressen und Geräte- oder Nutzeridentifikatoren mit Werbepartnern (wie Facebook und TikTok), um solche Werbeanzeigen an die Interessen der Nutzer anzupassen.
Werbung von Drittanbietern in den Apps von Falschparker oder in Verbindung mit unseren Services.





TowShare Group GmbH führt die oben genannten Aktivitäten mit der Begründung durch, dass sie für die berechtigten Interessen von Falschparker erforderlich sind, um die Nutzer über die von TowShare Group GmbH oder seinen Partnern angebotenen Services und Funktionen zu informieren.
7. Nicht-marketingbezogene Kommunikation. Falschparker kann Daten verwenden, um Umfragen und andere Mitteilungen zu versenden, die nicht zum Zwecke der Vermarktung der Services oder Produkte von Falschparker oder seinen Partnern dienen.

Falschparker führt die oben genannten Aktivitäten mit der Begründung durch, dass sie erforderlich sind, um unsere Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern zu erfüllen, oder um die berechtigten Interessen von Falschparker und seinen Nutzern zu wahren, indem Falschparker die Nutzer über Ereignisse informiert, die sich auf die Nutzung der Services von TowShare Group GmbH auswirken können.
8. Rechtliche Verfahren und Bestimmungen. Wir verwenden personenbezogene Daten, um Ansprüche oder Rechtsstreitigkeiten in Bezug auf die Nutzung der Services von Falschparker zu untersuchen oder zu bearbeiten, um die Anforderungen der geltenden Gesetze, Vorschriften oder Betriebserlaubnisse oder -vereinbarungen zu erfüllen, oder gemäß Gerichtsverfahren oder behördlichen Anfragen, einschließlich Anfragen von Strafverfolgungsbehörden.

TowShare Group GmbH führt die oben genannten Aktivitäten mit der Begründung aus, dass sie für die berechtigten Interessen von Falschparker an der Untersuchung und Beantwortung von Ansprüchen und Streitigkeiten im Zusammenhang mit der Nutzung der Services und Funktionen von Falschparker und/oder zur Einhaltung der geltenden gesetzlichen Vorschriften erforderlich sind.

Beschleunigen zu erkennen und sie über sichereres Abschleppen zu informieren. Wir nutzen zudem Daten von den Geräten der Abschleppfahrer, um den Abschleppporzess des gemeldeten Fahrzeugs zu verifizieren.
Das Verwenden von Standortdaten der Abschleppfahrer und Kommunikationen zwischen User und Abschleppfahrer, um Leerfahrten zu identifizieren, die durch Betrug erlangt oder verursacht wurden. Wenn beispielsweise festgestellt wird, dass ein Abschleppfahrer die Abholung eines falschgeparkten Auto verzögert, um eine Stornierung zu erreichen. Bitte kontaktiere den Kundensupport von Falschparker App, um gegen eine solche Anpassung Einspruch zu erheben.
Verwenden von Geräte-, Standort-, Profil-, Nutzungs- und anderen Daten, um weitere Arten von Betrug zu bekämpfen. Dies umfasst die Identifizierung betrügerischer Konten oder der betrügerischen Nutzung unserer Dienste, die Verhinderung der Nutzung unserer Services durch nicht autorisierte Kunden (User), die Verifizierung von Nutzeridentitäten im Zusammenhang mit bestimmten Zahlungsmitteln und die Verhinderung und Bekämpfung von unbefugtem Zugriff auf Nutzerkonten. In einigen Fällen, etwa wenn festgestellt wird, dass ein Nutzer das Empfehlungsprogramm von Falschparker App missbraucht oder betrügerische Dokumente eingereicht hat, kann ein solches Verhalten zur automatischen Deaktivierung führen. In der Schweiz oder wo anderweitig gesetzlich vorgeschrieben, kann es zu einer Deaktivierung nach einer menschlichen Überprüfung führen. Bitte wende dich an den Kundensupport von Falschparker App, um einer solchen Deaktivierung zu widersprechen.
Verwenden von Nutzer-Bewertungen, gemeldeten Vorfällen, und anderem Feedback, um das Einhalten unserer Community-Richtlinien zu fördern und als Grundlage für das Deaktivieren der Konten von Nutzern mit schlechten Bewertungen oder solchen, die anderweitig gegen diese Richtlinien in bestimmten Ländern verstoßen haben. In der Schweiz oder wo anderweitig gesetzlich vorgeschrieben, erfolgt diese Deaktivierung nur nach einer menschlichen Überprüfung und/oder der Möglichkeit, Einspruch einzulegen.Bitte wende dich an den Kundensupport von Falschparker App, um einer solchen Deaktivierung zu widersprechen.
Nutzung von Abschleppfahrerdaten (wie Standort, Bewertungen und Geschlecht, sofern gesetzlich zulässig) und Userdaten (wie Bewertungen, Abschlepport), um Paarungen von Nutzern vorherzusehen und zu vermeiden, die zu einem erhöhten Konfliktrisiko führen können (beispielsweise weil ein Nutzer zuvor einem anderen eine Ein-Sterne-Bewertung gegeben hat).

Falschparker App führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern und/oder zur Wahrung der berechtigten Sicherheitsinteressen von Falschparker App, unseren Nutzern und der Öffentlichkeit erforderlich sind.

3. Kunden-Support. Falschparker App verwendet die erfassten Informationen (einschließlich Anrufaufzeichnungen), um den Kundensupport bereitzustellen, einschließlich der Untersuchung und Bearbeitung von Anliegen der Nutzer und der Überwachung und Verbesserung unserer Reaktionen und Prozesse im Kundensupport.

Falschparker App führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern oder zum Zwecke des berechtigten Interesses von Falschparker App an der Überwachung und Verbesserung seiner Kundenserviceleistungen erforderlich sind.

4. Forschung und Entwicklung. Wir nutzen personenbezogene Daten zum Testen, Erforschen, Analysieren sowie für die Produktentwicklung und maschinelles Lernen, um die Nutzererfahrung zu verbessern. Dies hilft uns dabei, unsere Services angenehmer und benutzerfreundlicher zu gestalten, die Sicherheit unserer Services zu verbessern und neue Services und Funktionen zu entwickeln.

Falschparker App führt die oben genannten Aktivitäten mit der Begründung aus, dass sie für die berechtigten Interessen von Falschparker App an der Verbesserung und Entwicklung neuer Services und Funktionen erforderlich sind.

5. Ermöglichen der Kommunikation zwischen Nutzern und Abschleppfaher. Ein User kann z. B. einem Abschleppfaher eine Textnachricht schicken oder ihn anrufen, um den Abschlepport zu bestätigen; ein Abschleppfaher kann einen User kontaktieren, um Informationen zu einem Abschleppauftrag zu bekommen.
Falschparker App führt die oben genannten Aktivitäten mit der Begründung durch, dass sie zur Erfüllung der Bedingungen unserer Vereinbarungen mit Nutzern erforderlich sind.

6. Marketing und Werbung. Falschparker App verwendet Daten (mit Ausnahme der Daten von Gastnutzern), um seine Services und diejenigen von Partnern von Falschparker App zu vermarkten. Dazu gehört möglicherweise:

Das Versenden von E-Mails, Textnachrichten, Push-Benachrichtigungen und In-App-Nachrichten oder anderen Mitteilungen zu Marketing- oder Werbezwecken für Produkte, Services, Funktionen, Angebote, Aktionen, Verlosungen, Nachrichten und Veranstaltungen von Falschparker App.

Dies kann die Verwendung von Standort-, Abschleppaufgtrag- und Nutzungsdaten zum Senden von Marketing-Mitteilungen umfassen, die auf der Grundlage der beobachteten oder abgeleiteten Interessen und Merkmale der


C. Cookies und Drittanbieter-Technologien
Falschparker und seine Partner benutzen Cookies und andere Identifikationstechnologien in unseren Apps, Websites, E-Mails und Online-Werbungen .
Cookies sind kleine Textdateien, die von Websites, Apps, Online-Medien und Werbeanzeigen auf Browsern oder Geräten gespeichert werden. Falschparker nutzt Cookies und ähnliche Technologien u. a. zu folgenden Zwecken:
Authentifizierung von Nutzern
Speichern von Nutzerpräferenzen und -einstellungen
Bestimmen der Beliebtheit von Inhalten
Erfassen und Bewerten der Effektivität von Werbekampagnen
Analysieren der Seitenaufrufe und Trends und Entwickeln eines allgemeinen Verständnisses des Online-Verhaltens und der Interessen der Personen, die mit unseren Services interagieren
Wir können auch anderen erlauben, uns Aufrufquoten und Analyseservices zur Verfügung zu stellen, in unserem Namen Werbung im Internet zu schalten und die Performance solcher Werbungen zu verfolgen und darüber zu berichten. Diese Einheiten dürfen Cookies, Web-Beacons, SDK und andere Technologien nutzen, um das Gerät zu identifizieren, mit dem Besucher unsere Website besuchen, und auch wenn sie andere Websites und Services besuchen.
Weitere Informationen zur Nutzung von Cookies und anderen in diesem Abschnitt beschriebenen Technologien findest du in unserer Cookie-Richtlinie.


D. Weitergabe und Offenlegung von Daten
Einige der Services und Funktionen von Falschparker erfordern, dass wir Daten an andere Nutzer weitergeben, oder dies auf den Wunsch eines Nutzers hin tun. Wir können solche Daten auch mit unseren verbundenen Unternehmen, Tochtergesellschaften und Partnern teilen, wenn dies aus rechtlichen Gründen oder in Zusammenhang mit Ansprüchen oder Streitigkeiten erforderlich ist.
Falschparker kann Daten wie folgt weitergeben:
1. An andere Nutzer

Dies umfasst die Weitergabe folgender Daten:
Vorname, Nachname, Strasse, PLZ, Bewertungen sowie Abschlepperort des gemeldeten Falschparker an die Abschleppfahrer.

Außerdem stellen wir Kunden und Abschleppfahrer Belege zur Verfügung, die Informationen wie die GEO Kordinaten, Bild und Videomaterial den Vor-/ Nachname und Telefonnummer des Abschleppfahrers oder enthalten. Diesen Belegen werden weitere Informationen hinzugefügt, wenn dies gesetzlich vorgeschrieben ist.

2. Auf Wunsch des Nutzers

Dies umfasst die Weitergabe von Daten an:

Notruf: Wir bieten Funktionen an, mit denen Nutzer ihre Daten im Notfall oder nach bestimmten Vorfällen an die Polizei, Feuerwehr und Rettungsdienste weitergeben können. „Wahlmöglichkeiten und Transparenz“ und „Weitergabe von Daten im Notfall“.  
3. An die Öffentlichkeit

Fragen oder Kommentare von Nutzern, die über öffentliche Foren wie die Blogs oder Social-Media-Seiten von Falschparker eingesendet werden, können für die Öffentlichkeit einsehbar sein. Hierzu gehören auch Daten, die in den eingesandten Fragen oder Kommentaren enthalten sind.
4. An den Inhaber des Falschparker Kontos

Wenn ein Nutzer Services über ein Konto erhält, das einer anderen Partei gehört, können wir seine Auftragsinformationen, einschließlich Standortdaten, mit dem Eigentümer dieses Kontos teilen. Dies geschieht z. B. in folgenden Fällen:
Ein User / Kunde nutzt die Falschparker App for Business Unternehmensprofil seines Arbeitgebers




E. Speichern und Löschen von Daten
Falschparker speichert Nutzerdaten so lange, wie es für die oben beschriebenen Zwecke erforderlich ist.
Falschparker speichert Nutzerdaten so lange, wie es für die oben beschriebenen Zwecke erforderlich ist. Der Zeitraum, über den wir Nutzerdaten speichern, hängt von der Art der Daten, der Kategorie des Nutzers, auf den sich die Daten beziehen, und den Zwecken ab, für die wir die Daten erhoben haben.
Die Dauer, für die Falschparker Nutzerdaten speichert, kann ferner durch rechtliche und regulatorische Anforderungen, Zwecke der Sicherheit und Betrugsprävention oder durch Probleme im Zusammenhang mit dem Nutzerkonto bestimmt werden, z. B. bei noch ausstehenden Beträgen oder nicht beigelegten Ansprüchen oder Streitigkeiten.

Nutzer können jederzeit die Löschung ihrer Konten beantragen. Aufgrund gesetzlicher oder behördlicher Anforderungen oder aus den in dieser Richtlinie aufgeführten Gründen kann Falschparker Nutzerdaten auch nach einer Löschanfrage speichern.
V. Aktualisierungen der Datenschutzerklärung
Wir dürfen diese Datenschutzerklärung gelegentlich aktualisieren. 
Wir dürfen diese Datenschutzerklärung gelegentlich aktualisieren. Sollten wir signifikante Änderungen vornehmen, werden wir die Nutzer im Vorfeld durch die Falschparker App oder auf anderem Wege, wie etwa E-Mail, über die Änderungen informieren. Wir empfehlen den Nutzern, diese Datenschutzerklärung regelmäßig im Hinblick auf die jüngsten Informationen zu unseren Datenschutzpraktiken zu überprüfen.
Die Nutzung unserer Services nach einer Aktualisierung stellt eine Einwilligung in die aktualisierte Datenschutzerklärung in dem vom Gesetz erlaubten Maße dar.`,
};
