export const dataProtection = {
  dataProtectionContent: `I. Introduction
Lorsque vous utilisez l'application Falschparker, vous nous confiez vos données personnelles. Nous nous engageons à préserver cette confiance. Par conséquent, nous souhaitons vous aider à comprendre nos pratiques en matière de confidentialité.

Dans cette déclaration de confidentialité, nous décrivons quelles données personnelles ("données") nous collectons, comment elles sont utilisées et partagées, ainsi que les choix dont vous disposez concernant ces données. Nous vous recommandons de lire ce texte avec notre aperçu de la protection des données, qui explique les points clés de nos pratiques en matière de confidentialité.

II. Aperçu
A. Portée
Cette déclaration de confidentialité s'applique aux utilisateurs des services de TowShare Group GmbH en Suisse, y compris les utilisateurs de l'application Falschparker, des sites web, des fonctionnalités ou d'autres services.

B. Responsables du traitement des données et transfert de données
TowShare Group GmbH (Oberdorfstrasse 15, 8001 Zurich, Suisse) est le responsable des données collectées en lien avec l'utilisation des services de TowShare Group GmbH en Suisse.

TowShare Group GmbH exploite et traite les données personnelles en Suisse. Peu importe où vous vous trouvez – vos données vous accompagnent pour que nous puissions vous offrir une expérience fluide. Nous respectons également vos droits en matière de protection des données, que vous soyez en Suisse ou ailleurs.

Pour garantir un niveau de protection des données équivalent partout où vous utilisez nos services, TowShare Group GmbH en Suisse a mis en place les mesures suivantes :

1. Politiques et procédures pour limiter l'accès et le traitement des données personnelles à des fins définies ;
2. formations spécifiques du personnel chargé de la gestion des données personnelles ;
3. protection fiable des données lors de leur transfert entre l'application Falschparker et nos serveurs, et pendant leur traitement sur nos serveurs. Cela inclut le chiffrement et l'utilisation de données pseudonymisées pour protéger l'identité complète des utilisateurs contre toute divulgation non autorisée.

Pour simplifier l'exercice de vos droits en matière de protection des données, vous pouvez adresser vos demandes via l'un des canaux mentionnés ci-dessus à TowShare Group GmbH, qui les traitera centralement au nom du responsable concerné. Les questions, commentaires et plaintes concernant l'utilisation des données par TowShare Group GmbH peuvent être envoyés par e-mail à info@towshare.ch et info@falsch-parker.ch. Vous pouvez également utiliser cet e-mail pour contacter le délégué à la protection des données de TowShare Group GmbH.

De plus, les données de localisation précises collectées lors de la création de frais de gestion et de demandes de remorquage via l'appareil de l'utilisateur sont associées au compte du conducteur de dépanneuse, même si l'utilisateur n'a pas activé la collecte de données de localisation précises sur son appareil. Ces données sont utilisées pour des fins telles que la création de preuves, le support client, la détection de fraude, les questions d'assurance et les litiges juridiques.

3. Données provenant d'autres sources, incluant :

Les utilisateurs participant à nos programmes de recommandation. Par exemple, si un utilisateur recommande une autre personne, nous recevons les données de cette personne de l'utilisateur ayant fait la recommandation.
Les titulaires de comptes Falschparker qui demandent des services pour eux-mêmes ou au nom d'autres utilisateurs (tels que des locataires, des employés, des amis ou des membres de la famille), ou qui permettent à d'autres utilisateurs de demander ou de recevoir des services via leurs comptes professionnels.
Les utilisateurs ou d'autres tiers fournissant des informations en lien avec des réclamations ou des litiges.

Les fournisseurs qui nous aident à vérifier l'identité des utilisateurs, leurs informations d'antécédents et leur aptitude au travail, ou qui vérifient les utilisateurs dans le cadre de sanctions, de la lutte contre le blanchiment d'argent ou des exigences de connaissance du client.

Nom de l'entreprise : TowShare Group GmbH
Rue : Oberdorfstrasse 15
Code postal : 8001
Ville : Zurich
Pays : Suisse

B. Comment nous utilisons les données personnelles
Falschparker utilise les données pour permettre des services de remorquage fiables et professionnels, ainsi que d'autres produits et services. Nous utilisons également ces données pour :
assurer la sécurité de nos utilisateurs et services
fournir un support client
mener des recherches et du développement
faciliter la communication entre utilisateurs
pour le marketing et la publicité
envoyer des communications non commerciales aux utilisateurs
dans le cadre de procédures judiciaires

Nous utilisons les données collectées de la manière suivante :
1. Pour fournir nos services. L'application Falschparker utilise les données pour fournir, personnaliser, entretenir et améliorer ses services.

Cela inclut l'utilisation des données pour les objectifs suivants :
Création/mise à jour de comptes
Permettre les frais de gestion et les services de remorquage, tels que :
utiliser les données de localisation pour naviguer jusqu'aux contrevenants, calculer l'heure d'arrivée estimée et suivre la progression des demandes de remorquage.
Des fonctions de partage de données, comme le partage du nom et du numéro de téléphone du conducteur de dépanneuse pour faciliter le remorquage, le profil de l'utilisateur et les détails de remorquage pour le partage de l'heure d'arrivée estimée ainsi que les avis des utilisateurs.
Trouver des conducteurs de dépanneuse adaptés pour les utilisateurs commandant des services de remorquage. Les conducteurs peuvent être assignés selon leur disponibilité, leur emplacement et d'autres facteurs comme la probabilité d'acceptation d'une mission, ainsi que leur comportement ou préférences passées.
Fournir des mises à jour de l'application Falschparker
Mener les opérations nécessaires pour maintenir nos services, y compris la résolution de bugs et de problèmes opérationnels.

Falschparker mène les activités ci-dessus en raison de la nécessité de remplir les conditions de nos accords avec les utilisateurs, en accord avec eux, ou pour protéger les intérêts légitimes de Falschparker et de ses utilisateurs.
2. Sécurité et protection. Nous utilisons les données pour garantir la sécurité et l'intégrité de nos services et de nos utilisateurs. Cela inclut :
La vérification de l'identité des utilisateurs lors de la création de compte, par exemple sous forme de contrat de cession ou de bail, pour s'assurer que nos services ne sont pas utilisés par des utilisateurs se faisant passer pour d'autres, ce qui constitue un risque de sécurité.

Nous pourrions également utiliser la technologie de reconnaissance faciale pour faciliter le processus de connexion et empêcher l'utilisation non autorisée de nos services.

3. Support client. Falschparker utilise les informations collectées (y compris les enregistrements d'appels) pour fournir un support client, enquêter et traiter les préoccupations des utilisateurs, ainsi que surveiller et améliorer nos réponses et processus de support client.

Falschparker mène les activités ci-dessus en raison de la nécessité de remplir les conditions de nos accords avec les utilisateurs ou dans l'intérêt légitime de Falschparker de surveiller et d'améliorer ses services clients.
4. Recherche et développement. Nous utilisons les données personnelles pour tester, rechercher, analyser et développer des produits et le machine learning afin d'améliorer l'expérience utilisateur. Cela nous aide à rendre nos services plus agréables et conviviaux, à renforcer la sécurité de nos services et à développer de nouveaux services et fonctionnalités.

Falschparker mène les activités ci-dessus pour protéger les intérêts légitimes de TowShare Group GmbH en matière d'amélioration et de développement de nouveaux services et fonctionnalités.
5. Faciliter la communication entre utilisateurs et conducteurs de dépanneuse. Un conducteur de dépanneuse peut, par exemple, envoyer un message texte à un utilisateur ou l'appeler pour confirmer le lieu de remorquage d'un contrevenant.

TowShare Group GmbH mène les activités ci-dessus en raison de la nécessité de remplir les conditions de nos accords avec les utilisateurs.
6. Marketing et publicité. TowShare Group GmbH utilise les données pour promouvoir ses services et ceux de ses partenaires Falschparker. Cela peut inclure :
L'envoi d'e-mails, de SMS, de notifications push et de messages dans l'application ou d'autres communications marketing ou promotionnelles pour les produits, services, fonctionnalités, offres, promotions, concours, nouvelles et événements de TowShare Group GmbH.

Cela peut inclure l'utilisation de données de localisation, d'historique de commandes et de données d'utilisation pour envoyer des messages marketing personnalisés basés sur les intérêts et caractéristiques observés ou déduits des utilisateurs.

C. Cookies et technologies de tiers
Falschparker et ses partenaires utilisent des cookies et d'autres technologies d'identification dans nos applications, sites web, e-mails et publicités en ligne.
Les cookies sont de petits fichiers texte stockés sur les navigateurs ou appareils par les sites web, applications, médias en ligne et publicités. Falschparker utilise des cookies et technologies similaires pour :
Authentifier les utilisateurs
Stocker les préférences et paramètres des utilisateurs
Déterminer la popularité du contenu
Évaluer l'efficacité des campagnes publicitaires
Analyser les pages vues et tendances et comprendre le comportement en ligne et les intérêts des personnes interagissant avec nos services
Nous permettons également à d'autres de fournir des services de mesure et d'analyse, de diffuser des publicités sur internet en notre nom et de suivre et évaluer la performance de ces publicités. Ces entités peuvent utiliser des cookies, balises web, SDK et autres technologies pour identifier l'appareil des visiteurs sur notre site et d'autres sites et

 services.
Pour plus d'informations sur l'utilisation des cookies et autres technologies décrites dans cette section, consultez notre Politique sur les cookies.

D. Partage et divulgation de données
Certains services et fonctionnalités de Falschparker nécessitent que nous partagions des données avec d'autres utilisateurs, ou que nous le fassions à la demande d'un utilisateur. Nous pouvons également partager ces données avec nos sociétés affiliées, filiales et partenaires lorsque cela est requis pour des raisons juridiques ou en lien avec des réclamations ou litiges.
Falschparker peut partager les données comme suit :
1. Avec d'autres utilisateurs

Cela inclut le partage des informations suivantes :
Nom, prénom, adresse, code postal, avis, ainsi que le lieu de remorquage du contrevenant signalé aux conducteurs de dépanneuse.

Nous fournissons également aux clients et conducteurs de dépanneuse des preuves incluant des informations telles que les coordonnées GPS, les images et vidéos, le nom et le numéro de téléphone du conducteur de dépanneuse. Des informations supplémentaires peuvent être ajoutées lorsque la loi l'exige.

2. À la demande de l'utilisateur

Cela inclut le partage de données avec :

Urgence : Nous proposons des fonctions permettant aux utilisateurs de partager leurs données en cas d'urgence ou d'incidents spécifiques avec la police, les pompiers et les services d'urgence. Voir "Choix et transparence" et "Partage de données en cas d'urgence".
3. Au public

Les questions ou commentaires des utilisateurs soumis via des forums publics comme les blogs ou pages de médias sociaux de Falschparker peuvent être visibles du public. Cela inclut également les données contenues dans les questions ou commentaires soumis.
4. Au titulaire du compte Falschparker

Lorsqu'un utilisateur utilise des services via un compte appartenant à une autre partie, nous pouvons partager ses informations de commande, y compris les données de localisation, avec le propriétaire de ce compte. Cela se produit, par exemple, dans les cas suivants :
Un utilisateur utilise le profil d'entreprise Falschparker App for Business de son employeur.

E. Conservation et suppression des données
Falschparker conserve les données utilisateur aussi longtemps que nécessaire aux fins décrites ci-dessus. La durée de conservation des données utilisateur dépend de la nature des données, de la catégorie d'utilisateur concernée et des objectifs pour lesquels nous avons collecté les données.
La durée de conservation des données utilisateur peut également être déterminée par des exigences légales et réglementaires, des objectifs de sécurité et de prévention de la fraude, ou des problèmes liés au compte utilisateur, tels que des soldes impayés ou des réclamations ou litiges en suspens.

Les utilisateurs peuvent demander la suppression de leurs comptes à tout moment. En raison d'exigences légales ou réglementaires ou pour les raisons décrites dans cette politique, Falschparker peut conserver les données utilisateur même après une demande de suppression.
V. Mises à jour de la politique de confidentialité
Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Si nous apportons des changements significatifs, nous informerons les utilisateurs à l'avance via l'application Falschparker ou par d'autres moyens, comme par e-mail. Nous recommandons aux utilisateurs de consulter cette politique de confidentialité régulièrement pour rester informés des dernières informations sur nos pratiques en matière de confidentialité.
L'utilisation de nos services après une mise à jour constitue un consentement à la politique de confidentialité mise à jour dans la mesure permise par la loi.`,
};
