import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { MapIcon } from 'components/Icons/MapIcon/MapIcon';
import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { Map } from 'components/Map/Map';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useEffect, useState, MouseEvent, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Address, Position } from 'types/general';
import { ParkingZone } from 'types/ParkingZone';
import { getAddress } from 'utils/getAddress';
import { getPosition } from 'utils/getPosition';
import { Popup } from 'components/Popup/Popup';
import { CloseReportButton } from 'components/CloseReportButton/CloseReportButton';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useAccountType } from 'hooks/useAccountType';
import { SelectButton } from 'components/SelectButton/SelectButton';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PARKING_ZONES_GEO } from 'graphql/queries/parkingZonesGeo';
import { PROCESS_GAS_STATION_BILL } from 'graphql/mutations/processGasStationBill';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { CREATE_GAS_STATION_REPORT_NEW } from 'graphql/mutations/createGasStationReportNew';
import { ZURICH_POSITION } from 'data/position';
import { appContext } from '../../../App';
import * as Sentry from '@sentry/react';
import { useUploadGasStationReport } from 'api/useUploadGasStationReport';

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
  gap: 20px;
`;
const StyledContainer = styled.div`
  .item {
    margin-bottom: 16px;
  }
`;

const StyledParkingDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const StyledBottomContainer = styled.div`
  height: 10px;
  min-height: 10px;
`;
export const OrderAddress = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [position, setPosition] = useState<Position | undefined>(undefined);
  const [isAddressCorrect, setIsAddressCorrect] = useState<boolean>(true);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState<boolean>(false);
  const [selectedParking, setSelectedParking] = useState<ParkingZone | null>(
    null,
  );
  const [parkings, setParkings] = useState<ParkingZone[] | null>(null);
  const [layoutMode, setLayoutMode] = useState<'open' | 'close'>('close');
  const [isParkingNotSelected, setIsParkingNotSelected] =
    useState<boolean>(false);
  const [isWrongAddress, setIsWrongAddress] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, setValue, getValues, watch } = methods;
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const { windowHeight } = useWindowHeight();
  const { mutate } = useUploadMutation();
  const { uploadImage } = useUploadGasStationReport();
  const [parkingZonesGeo] = useLazyQuery(PARKING_ZONES_GEO, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      if (data.parkingZonesGeo?.length) {
        setParkings(data.parkingZonesGeo);
      }
    },
  });

  const [processGasStationBill, { loading: processGasStationBillLoading }] =
    useMutation(PROCESS_GAS_STATION_BILL, {
      onCompleted: (data) => {
        if (!data) return;
      },
      onError: (error) => {
        setIsError(true);
        throw new Error(error.message);
      },
    });

  const onSubmit = () => {
    (document.activeElement as HTMLElement).blur();
    if (isAddressCorrect) {
      if (!reportDetails) return;
      setIsLoading(true);
      if (reportDetails.type === 3) {
        let address;
        let addressData;
        if (selectedParking) {
          address = `${selectedParking.streetName} ${selectedParking.streetNumber}, ${selectedParking.zipCode} ${selectedParking.city}`;
          addressData = {
            fullAddress: address,
            street: selectedParking.streetName,
            streetNumber: selectedParking.streetNumber,
            zipCode: selectedParking.zipCode,
            city: selectedParking.city,
          };
        } else {
          address = `${getValues('reportStreet')} ${getValues(
            'reportStreetNumber',
          )}, ${getValues('reportZipCode')} ${getValues('reportCity')}`;
          addressData = {
            fullAddress: address,
            street: getValues('reportStreet'),
            streetNumber: getValues('reportStreetNumber'),
            zipCode: getValues('reportZipCode'),
            city: getValues('reportCity'),
          };
        }
        mutate(CREATE_GAS_STATION_REPORT_NEW, {
          variables: {
            ...addressData,
            ...reportDetails.carDetails,
            latitude:
              position?.length > 0 ? position[0] : ZURICH_POSITION.latitude,
            longitude:
              position?.length > 0 ? position[1] : ZURICH_POSITION.longitude,
            bill: reportDetails?.bill,
            parkingZoneId: selectedParking?.id,
            inStore: reportDetails?.inStore,
            price: parseFloat(reportDetails?.price.toString()),
          },
        })
          .then(async (result) => {
            setIsLoading(false);
            if (result.data) {
              processGasStationBill({
                variables: { hash: result.data.createGasStationReportNew.hash },
              });
              setReportDetails({
                ...reportDetails,
                hash: result.data.createGasStationReportNew.hash,
                id: result.data.createGasStationReportNew.id,
              });
              // NOTE: This is use for uploading gas station image
              await uploadImage({
                hash: result.data.createGasStationReportNew.hash,
                images: reportDetails?.images,
              });
              if (isNotUserAccount) {
                setReportDetails({
                  ...reportDetails,
                  images: [],
                  videos: [],
                });
                navigate(
                  reportDetails.type === 1
                    ? `/select-parking/${reportDetails.hash}`
                    : '/success-order',
                );
              } else {
                navigate('/success-order');
              }
            }
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
            setIsLoading(false);
            setIsError(true);
          });
      } else {
        if (selectedParking) {
          const address = `${selectedParking.streetName} ${selectedParking.streetNumber}, ${selectedParking.zipCode} ${selectedParking.city}`;
          const addressData: Address = {
            fullAddress: address,
            street: selectedParking.streetName,
            streetNumber: selectedParking.streetNumber,
            zipCode: selectedParking.zipCode,
            city: selectedParking.city,
          };
          setReportDetails({
            ...reportDetails,
            parkingZoneId: selectedParking.id,
            address: {
              ...addressData,
              position,
            },
          });
          setTimeout(() => {
            navigate(`/confirmation-pickup`);
          }, 500);
        } else {
          setIsLoading(false);
          if (parkings && parkings?.length > 0) {
            console.log('please, select parking');
            setIsParkingNotSelected(true);
          } else {
            console.log('please, choose another address');
            setIsWrongAddress(true);
          }
        }
      }
    }
  };

  const handleLocationClick = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function async(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setPosition([latitude, longitude]);
        const address = await getAddress([latitude, longitude]);
        if (address) {
          setValue('reportStreet', address.street);
          setValue('reportStreetNumber', address.streetNumber);
          setValue('reportZipCode', address.zipCode);
          setValue('reportCity', address.city);
        }
        if (isNotUserAccount) {
          parkingZonesGeo({ variables: { latitude, longitude } });
        }
      });
    }
  };

  const handleGetPosition = async () => {
    const address = `${getValues('reportStreet')}+${getValues(
      'reportStreetNumber',
    )}+,+${getValues('reportZipCode')}+${getValues('reportCity')}`;
    const encodeAddress = encodeURI(address);
    const addressData = await getPosition(encodeAddress);
    if (addressData) {
      setIsAddressCorrect(true);
      setPosition([addressData.lat, addressData.lng]);
      parkingZonesGeo({
        variables: { latitude: addressData.lat, longitude: addressData.lng },
      });
    } else {
      setIsAddressCorrect(false);
    }
  };

  const handleParkingSelect = (e: MouseEvent, parking: ParkingZone) => {
    e.preventDefault();
    setSelectedParking(parking);
  };

  const handleErrorPopupClose = () => {
    setIsErrorPopupOpened(false);
    navigate('/');
  };

  useEffect(() => {
    const street = getValues('reportStreet');
    const streetNumber = getValues('reportStreetNumber');
    const zipCode = getValues('reportZipCode');
    const city = getValues('reportCity');
    if (street && streetNumber && zipCode && city) {
      handleGetPosition();
      setLayoutMode('open');
    }
  }, [
    watch('reportStreet'),
    watch('reportStreetNumber'),
    watch('reportZipCode'),
    watch('reportCity'),
  ]);

  useEffect(() => {
    if (!reportDetails) return;
    if (!reportDetails.address) return;
    setValue('reportStreet', reportDetails.address.street);
    setValue('reportStreetNumber', reportDetails.address.streetNumber);
    setValue('reportZipCode', reportDetails.address.zipCode);
    setValue('reportCity', reportDetails.address.city);

    setPosition([
      reportDetails.address.position?.length > 0
        ? reportDetails.address.position[0]
        : ZURICH_POSITION.latitude,
      reportDetails.address.position?.length > 0
        ? reportDetails.address.position[1]
        : ZURICH_POSITION.longitude,
    ]);
  }, [reportDetails]);
  useEffect(() => {
    setIsLoading(processGasStationBillLoading);
  }, [processGasStationBillLoading]);

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    const reportStreetInput = document.getElementById('reportStreet');
    const reportStreetNumberInput =
      document.getElementById('reportStreetNumber');
    const reportZipCodeInput = document.getElementById('reportZipCode');
    const reportCityInput = document.getElementById('reportCity');

    reportStreetInput.addEventListener('keypress', handleEnterPress);
    reportStreetNumberInput.addEventListener('keypress', handleEnterPress);
    reportZipCodeInput.addEventListener('keypress', handleEnterPress);
    reportCityInput.addEventListener('keypress', handleEnterPress);
  }, []);

  return (
    <Layout
      isBackBtn
      isWithHeightAnimation={true}
      mode={layoutMode === 'close' ? 'medium' : 'top'}
      backgroundContent={
        <Map
          height={`calc(100vh - ${windowHeight - 295}px)`}
          position={position && position}
        />
      }
      outsideElements={
        <div>
          <Popup
            isPopupOpened={isErrorPopupOpened}
            type="info"
            content={t('allowedCarPopup')}
            onDecline={handleErrorPopupClose}
            confirmText={t('back')}
          />
          <CloseReportButton
            onConfirmClick={() => {
              setReportDetails(null);
              navigate('/');
            }}
          />
          <Popup
            isPopupOpened={isParkingNotSelected}
            type="info"
            content={t('pleaseSelectParking')}
            onDecline={() => setIsParkingNotSelected(false)}
            confirmText={t('ok')}
          />
          <Popup
            isPopupOpened={isWrongAddress}
            type="info"
            content={t('pleaseChangeAddress')}
            onDecline={() => setIsWrongAddress(false)}
            confirmText={t('ok')}
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="reportAddressForm">
          <div>
            <Heading color="var(--black)">
              {t(
                reportDetails?.type === 3
                  ? 'gasStationAddressTitle'
                  : 'yourLocation',
              )}
            </Heading>
            <StyledContainer>
              <Input
                name="reportStreet"
                label={t('street')}
                mode="light"
                placeholder={t('streetPlaceholder')}
                isRequired
                requiredErrorMessage={t('requiredError')}
                icon={
                  <MapIcon
                    fill={userData?.appSettings?.primary_color}
                    stroke={userData?.appSettings?.primary_color}
                  />
                }
                onIconClick={handleLocationClick}
                type="text"
                bottomMarting="16"
              />
              <Input
                name="reportStreetNumber"
                label={t('streetNumber')}
                mode="light"
                placeholder={t('streetNumberPlaceholder')}
                isRequired
                requiredErrorMessage={t('requiredError')}
                type="text"
                bottomMarting="16"
              />
              <Input
                name="reportZipCode"
                label={t('zipCode')}
                mode="light"
                placeholder={t('zipCodePlaceholder')}
                isRequired
                requiredErrorMessage={t('requiredError')}
                type="text"
                bottomMarting="16"
              />
              <Input
                name="reportCity"
                label={t('city')}
                mode="light"
                placeholder={t('cityPlaceholder')}
                isRequired
                requiredErrorMessage={t('requiredError')}
                type="text"
                bottomMarting="16"
              />
              {!isAddressCorrect && (
                <Paragraph color="var(--error)" className="item">
                  {t('addressNotFound')}
                </Paragraph>
              )}
            </StyledContainer>
            {isNotUserAccount && layoutMode === 'open' && parkings?.length && (
              <div>
                <Heading color="var(--black)">{t('selectParkingArea')}</Heading>
                {parkings.map((parking) => (
                  <SelectButton
                    key={parking.id}
                    renderedContent={
                      <StyledParkingDetails>
                        <Paragraph color="var(--black)" dimension="s">
                          {parking?.user?.companyName
                            ? parking?.user?.companyName
                            : `${parking?.user?.firstName} ${parking?.user?.lastName}`}
                        </Paragraph>
                        <Paragraph color="var(--black)" weight="bold">
                          {parking.fullAddress}
                        </Paragraph>
                        <Paragraph color="var(--black)" weight="bold">
                          {parking.name}
                        </Paragraph>
                      </StyledParkingDetails>
                    }
                    onClick={(e: MouseEvent) => handleParkingSelect(e, parking)}
                    isSelected={selectedParking === parking}
                  />
                ))}
              </div>
            )}
          </div>
          <div>
            <Button type="submit" disabled={!isAddressCorrect}>
              {t(reportDetails?.type === 3 ? 'confirm' : 'next')}
            </Button>
            {isNotUserAccount && layoutMode === 'open' && parkings?.length && (
              <StyledBottomContainer />
            )}
          </div>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};
