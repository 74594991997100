import styled from 'styled-components/macro';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { device } from 'styles/devices';
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAnimation';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { appContext } from 'views/App';
import { ErrorPopup } from 'components/ErrorPopup/ErrorPopup';
import chDomainLogo from 'assets/ch/domainLogo.svg';
import deDomainLogo from 'assets/de/domainLogo.svg';

const domainLogo =
  process.env.REACT_APP_REGION === 'ch' ? chDomainLogo : deDomainLogo;

const StyledLayout = styled.div<{ minHeight: string }>`
  padding: 40px 24px;
  min-height: ${({ minHeight }) => minHeight};
  height: 100%;
  overflow: auto;
  background-color: white;
  @media ${device.laptop} {
    padding: 72px 120px;
  }
  .languageDropdown {
    p {
      color: var(--white-always);
    }
    path {
      stroke: var(--white-always);
    }
  }
`;
const StyledLogo = styled.img`
  width: 136px;
  height: 30px;

  @media ${device.laptop} {
    width: 282px;
    height: 62px;
  }
`;
const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

interface LayoutProps {
  children: ReactNode;
}

export const ParkingTicketsLayout = ({ children }: LayoutProps) => {
  const appContextStore = useContext(appContext);
  const isLoading = appContextStore?.isLoading;

  const { i18n } = useTranslation();
  const { windowHeight } = useWindowHeight();
  const elements = ['de', 'en', 'fr', 'it'];
  const [defaulElement, setDefaultElement] = useState<string>('');

  useEffect(() => {
    const lang = localStorage.getItem('language');
    i18n.changeLanguage(lang);
    setDefaultElement(lang);
  }, []);

  const handleLanguageChange = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  const webUrl =
    process.env.REACT_APP_REGION === 'ch'
      ? 'https://falsch-parker.ch/sg'
      : 'https://falschparker.io/de';
  return (
    <>
      <StyledLayout minHeight={`${windowHeight}px`}>
        <StyledTitleContainer>
          <StyledLogo src={domainLogo} alt="Falschparker Logo" />
          <Dropdown
            options={elements}
            mode="secondary"
            label=""
            handleSelectedOption={(element) => handleLanguageChange(element)}
            defaultOption={defaulElement}
            className="languageDropdown"
          />
        </StyledTitleContainer>

        {children}
        <div
          style={{
            marginTop: '20px',
            marginRight: '20px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
          }}
        >
          <a
            href={webUrl + '/terms-and-conditions'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            AGB
          </a>
          <a
            href={webUrl + '/imprint'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            Impressum
          </a>
        </div>
      </StyledLayout>
      {isLoading && <LoadingAnimation />}
      <ErrorPopup />
    </>
  );
};
