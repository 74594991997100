import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { IconButton } from 'components/IconButton/IconButton';
import { Heading } from 'components/Heading/Heading';
import { CopyIcon } from 'components/Icons/CopyIcon/CopyIcon';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { INIT_TRANSACTION } from 'graphql/mutations/initTransaction';
import { REPORT_BY_QR_CODE } from 'graphql/queries/reportByQRCode';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { ReportProps } from 'types/Report';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledForm = styled.div<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
    svg {
      width: 28px;
      height: 28px;
    }
  }
  .ebanking {
    margin: 30px 0 15px;
    font-weight: 600;
    @media ${device.laptop} {
      font-size: 24px;
      margin: 40px 0 20px;
      font-weight: 700;
    }
  }
  .subtitle {
    margin: 30px 0 10px;
    font-weight: 600;
    @media ${device.laptop} {
      font-size: 24px;
      margin: 40px 0 20px;
      font-weight: 700;
    }
  }
  .payDesktopButton {
    margin-bottom: 12px;
  }
`;
const StyledContent = styled.div`
  max-width: 793px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
    @media ${device.laptop} {
      display: none;
    }
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    button {
      max-width: 125px;
    }
  }
`;
const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--sub--black);
  text-align: center;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  font-size: var(--text-l);
  @media ${device.laptop} {
    margin-bottom: 0;
    padding-left: 0;
    max-width: max-content !important;
    width: max-content;
  }
`;
const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const StyledDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;
const StyledMobileDetail = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;
const StyledDesktopDetails = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
  }
`;
const StyledDesktopPaymentContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    .payDesktopButton {
      width: max-content;
      padding: 12px 24px;
      color: var(--white);
    }
  }
`;
const MobileChackboxContainer = styled.div`
  margin-top: 20px;
  @media ${device.laptop} {
    display: none;
  }
`;
export const PaymentInformation = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { windowHeight } = useWindowHeight();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<
    boolean | undefined
  >(undefined);
  const [initTransaction, { loading }] = useMutation(INIT_TRANSACTION, {
    onCompleted: (data) => {
      const paymentData = JSON.parse(data.initTransaction);
      window.location.assign(paymentData?.payment_link);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsError(true);
      throw new Error(error.message);
    },
  });
  const handleCopyClick = (content: string) => {
    navigator.clipboard.writeText(content);
  };
  const handlePayClick = () => {
    if (isCheckboxChecked) {
      initTransaction({ variables: { reportId: Number(reportData?.id) } });
    } else {
      setIsCheckboxChecked(false);
    }
  };
  const [reportData, setReportData] = useState<ReportProps | undefined>(
    undefined,
  );
  const [reportByQRCode, { loading: reportByQRCodeLoading }] = useLazyQuery(
    REPORT_BY_QR_CODE,
    {
      onCompleted: (data) => {
        setReportData(data.reportByQRCode);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        throw new Error(error.message);
      },
    },
  );
  useEffect(() => {
    const qrCodeId = params?.qrCodeId;
    if (!qrCodeId) return;
    sessionStorage.setItem('qrCodeId', qrCodeId);
    reportByQRCode({ variables: { qrCodeId: qrCodeId } });
  }, [params]);
  useEffect(() => {
    setIsLoading(loading || reportByQRCodeLoading);
  }, [loading, reportByQRCodeLoading]);

  console.log('ENABLE_FALSCH_PARKER_IO', process.env.REACT_APP_DOMAIN);

  return (
    <ParkingTicketsLayout>
      <StyledForm height={`${windowHeight}px`}>
        <StyledContent>
          <Heading color="var(--black-always)">{t('paymentInfoTitle')}</Heading>
          <Paragraph dimension="s" color="var(--sub--black)">
            {t('paymentInfoSubtitle')}
          </Paragraph>
          <StyledDesktopPaymentContainer>
            <Paragraph
              dimension="m"
              color="var(--sub--black)"
              weight="bolder"
              className="ebanking"
            >
              {t('paymentMethodTicket')}
            </Paragraph>
            <Button onBtnClick={handlePayClick} className="payDesktopButton">
              {t('pay')}
            </Button>
            <Checkbox
              text={t('acceptAGB')}
              onToggle={() => setIsCheckboxChecked((prev) => !prev)}
              error={isCheckboxChecked === false}
              isChecked={isCheckboxChecked}
              mode="dark"
              onLabelButtonClick={() => navigate('/payment-agb')}
              isButton={true}
              defaultCheckmarkColor="var(--black-always)"
            />
          </StyledDesktopPaymentContainer>
          <Paragraph
            dimension="m"
            color="var(--sub--black)"
            weight="bolder"
            className="ebanking"
          >
            {t('eBanking')}
          </Paragraph>
          <StyledDetailsContainer>
            <StyledDetail>
              <div>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('bankAccountNumber')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  CH31 0070 0114 8081 9276 2
                </Paragraph>
              </div>
              <IconButton
                onClick={() => handleCopyClick('CH31 0070 0114 8081 9276 2')}
              >
                <CopyIcon />
              </IconButton>
            </StyledDetail>
            <StyledDesktopDetails>
              <StyledDetail>
                <div>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('yourReferenceNo')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    00 31890 00010 00218 19721 01912
                  </Paragraph>
                </div>
                <IconButton
                  onClick={() =>
                    handleCopyClick('00 31890 00010 00218 19721 01912')
                  }
                >
                  <CopyIcon />
                </IconButton>
              </StyledDetail>
              <div>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                  textAlign="right"
                >
                  {t('amount')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                  textAlign="right"
                >
                  {process.env.REACT_APP_REGION === 'de' ? 'EUR' : 'CHF'}
                  {reportData?.fineValue}
                </Paragraph>
              </div>
            </StyledDesktopDetails>
            <StyledMobileDetail>
              <StyledDetail>
                <div>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('yourReferenceNo')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black-always)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    00 31890 00010 00218 19721 01912
                  </Paragraph>
                </div>
                <IconButton
                  onClick={() =>
                    handleCopyClick('00 31890 00010 00218 19721 01912')
                  }
                >
                  <CopyIcon />
                </IconButton>
              </StyledDetail>
            </StyledMobileDetail>
            <StyledMobileDetail>
              <div>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('amount')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  {process.env.REACT_APP_REGION === 'de' ? 'EUR' : 'CHF'}
                  {reportData?.fineValue}
                </Paragraph>
              </div>
            </StyledMobileDetail>
            <StyledDesktopDetails>
              <div>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('inFavorOf')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  dimension="m"
                  className="detailContent"
                >
                  TowShare Group GmbH, Oberdorfstrasse 15, 8001 Zürich
                </Paragraph>
              </div>
            </StyledDesktopDetails>
            <StyledMobileDetail>
              <div>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('inFavorOf')}
                </Paragraph>
                <Paragraph
                  color="var(--black-always)"
                  dimension="m"
                  className="detailContent"
                >
                  TowShare Group GmbH, Oberdorfstrasse 15, 8001 Zürich
                </Paragraph>
              </div>
            </StyledMobileDetail>
          </StyledDetailsContainer>
          <Paragraph
            dimension="m"
            color="var(--sub--black)"
            weight="bolder"
            className="subtitle"
          >
            {t('paymentSlip')}
          </Paragraph>
          <Paragraph dimension="s" color="var(--sub--black)">
            {process.env.REACT_APP_REGION === 'de'
              ? 'Wenn die Vertragsstrafe 2 Tage nach dem Parkvergehen nicht bezahlt ist, senden wir der verantwortlichen Person automatisch eine Umtriebsentschädigung mit Einzahlungsschein zu.'
              : t('paymentSlipDesc')}
          </Paragraph>
          <MobileChackboxContainer>
            <Checkbox
              text={t('acceptAGB')}
              onToggle={() => setIsCheckboxChecked((prev) => !prev)}
              error={isCheckboxChecked === false}
              isChecked={isCheckboxChecked}
              mode="dark"
              onLabelButtonClick={() => navigate('/payment-agb')}
              isButton={true}
              defaultCheckmarkColor="var(--black-always)"
            />
          </MobileChackboxContainer>
        </StyledContent>
        <StyledButtonContainer>
          <Button className="nextButton" onBtnClick={handlePayClick}>
            {t('pay')}
          </Button>
          <StyledButton onClick={() => navigate(-1)}>Zurück</StyledButton>
        </StyledButtonContainer>
      </StyledForm>
    </ParkingTicketsLayout>
  );
};
