import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useAuthContext } from 'context/auth';
import { DocumentNode } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';

export const useUploadMutation = () => {
  const { authToken } = useAuthContext();

  const createApolloClient = () => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: createUploadLink({
        uri: process.env.REACT_APP_FP_BE_URL + '/graphql',
        headers: { Authorization: `Bearer ${authToken}` },
      }),
    });
  };

  const client = createApolloClient();
  const mutate = (mutation: DocumentNode, { variables }) =>
    client.mutate({
      mutation,
      variables,
    });

  return { mutate };
};
