import { useProvideAuth } from 'hooks/useProvideAuth';
/**
 * Custom hook for uploading images related to a gas station report.
 * Uses authentication headers from `useProvideAuth` for secure requests.
 *
 * @returns {Object} An object containing the `uploadImage` function.
 */

/**
 * Uploads multiple images for a gas station report.
 *
 * @param {Object} params - The parameters for the upload.
 * @param {File[]} params.images - An array of image files to be uploaded.
 * @param {string} params.hash - A unique identifier for the report.
 * @returns {Promise<Response>} A promise that resolves with the server response.
 * @throws {Error} If the upload fails.
 *
 * @example
 * ```ts
 * try {
 *   const response = await uploadImage({
 *     hash: 'xyz123',
 *     images: [file1, file2]
 *   });
 *   console.log('Upload successful:', response);
 * } catch (error) {
 *   console.error('Upload failed:', error);
 * }
 * ```
 */
export const useUploadGasStationReport = () => {
  const { getAuthHeaders } = useProvideAuth();
  const uploadImage = async ({
    images,
    hash,
  }: {
    images: File[];
    hash: string;
  }) => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('files[]', image);
    });
    const response = await fetch(
      `${process.env.REACT_APP_FP_BE_URL}/api/upload-files/${hash}/gas-station-report`,
      {
        method: 'POST',
        body: formData,
        headers: getAuthHeaders(),
      },
    );
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Image upload failed: ${errorMessage}`);
    }
    console.log(response);
    return response;
  };
  return {
    uploadImage,
  };
};
