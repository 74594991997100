import { Input } from 'components/Input/Input';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { UploadFileInput } from 'views/ParkingTickets/UploadFileInput/UploadFileInput';
import { Textarea } from 'components/Textarea/Textarea';
import { CREATE_OBJECTION_TO_TICKET } from 'graphql/mutations/createObjectionToTicket';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { appContext } from 'views/App';

const StyledForm = styled.form<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
  }
  .raiseInput {
    min-height: 146px;
    align-items: flex-start;
    @media ${device.laptop} {
      height: 173px;
    }
    textarea {
      height: 100px;
      @media ${device.laptop} {
        height: 130px;
      }
    }
  }
`;
const StyledContent = styled.div`
  max-width: 590px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
    p,
    span {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
`;
const StyledLinkContainer = styled.div`
  margin: 10px 0 20px;
  &:last-child {
    margin: 20px 0 10px;
  }
`;
const StyledSpan = styled.span`
  color: var(--sub--black);
  font-size: var(--text-s);
  line-height: 16px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    button {
      max-width: 125px;
    }
  }
`;
const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--sub--black);
  text-align: center;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  font-size: var(--text-l);
  @media ${device.laptop} {
    margin-bottom: 0;
    padding-left: 0;
    max-width: max-content !important;
    width: max-content;
  }
`;
const StyledFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  span {
    color: var(--black-always);
  }
`;
export const ObjectionRaise = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const methods = useForm();
  const { setValue, handleSubmit, getValues } = methods;
  const params = useParams();
  const navigate = useNavigate();
  const [addedFiles, setAddedFiles] = useState<File[] | []>([]);
  const { mutate } = useUploadMutation();
  const { windowHeight } = useWindowHeight();

  useEffect(() => {
    if (!params && !params.id) return;
    setValue('ticketNumber', params.id);
  }, [params]);
  const onSubmit = () => {
    setIsLoading(true);
    mutate(CREATE_OBJECTION_TO_TICKET, {
      variables: {
        qrCodeId: params.qrCodeId,
        email: getValues('email'),
        text: getValues('note'),
        files: addedFiles,
      },
    })
      .then(() => {
        setIsLoading(false);
        navigate('/success-objection-raise');
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };
  return (
    <ParkingTicketsLayout>
      <FormProvider {...methods}>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          height={`${windowHeight}px`}
        >
          <StyledContent>
            <Heading color="var(--black-always)">
              {process.env.REACT_APP_REGION === 'de'
                ? 'Sind Sie der Meinung, dass die Vertragsstrafe ungerechtfertig oder falsch ist?'
                : t('objectionRaiseTitle')}
            </Heading>
            <Paragraph dimension="s" color="var(--sub--black)">
              {process.env.REACT_APP_REGION === 'de'
                ? 'Wenn Sie der Auffassung sind, dass die Vertragsstrafe ungerechtfertigt oder unzureichend ist, können Sie eine Streitigkeit einreichen.'
                : t('objectionRaiseSubtitle')}
            </Paragraph>
            <StyledLinkContainer>
              <StyledSpan>{t('objectionRaiseDescription')}</StyledSpan>
            </StyledLinkContainer>
            <StyledFieldsContainer>
              <Input
                name="email"
                isRequired
                label={t('email')}
                placeholder={t('objectionRaiseEmailPlaceholder')}
                mode="light"
              />
              <Textarea
                name="note"
                label={t('note')}
                mode="light"
                placeholder={t('notePlaceholder')}
                maxLength={2000}
                isRequired
              />
              <UploadFileInput
                name={'files'}
                label={t('objectionRaiseUpload')}
                handleMediaChange={(files) => setAddedFiles(files)}
              />
            </StyledFieldsContainer>
          </StyledContent>
          <StyledButtonContainer>
            <Button className="nextButton" type="submit">
              Weiter
            </Button>
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Zurück
            </StyledButton>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
    </ParkingTicketsLayout>
  );
};
