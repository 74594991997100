import { useProvideAuth } from 'hooks/useProvideAuth';
/**
 * Custom hook for uploading car owner-related image files.
 * This includes the driver's ID photo and the vehicle's plate photo.
 * Uses authentication headers from `useProvideAuth` for secure requests.
 *
 * @returns {Object} An object containing the `uploadCarOwnerImageFiles` function.
 */

/**
 * Uploads car owner images (driver ID and plate photo) to the server.
 *
 * @param {Object} params - The parameters for the upload.
 * @param {string} params.hash - A unique identifier for the car owner.
 * @param {File} params.driverIdPhoto - The driver's ID photo file.
 * @param {File} params.platePhoto - The vehicle's plate photo file.
 * @returns {Promise<Response>} A promise that resolves with the server response.
 * @throws {Error} If the upload fails.
 *
 * @example
 * ```ts
 * try {
 *   const response = await uploadCarOwnerImageFiles({
 *     hash: 'abc123',
 *     driverIdPhoto: driverFile,
 *     platePhoto: plateFile
 *   });
 *   console.log('Upload successful:', response);
 * } catch (error) {
 *   console.error('Upload failed:', error);
 * }
 * ```
 */
export const useUploadImageForCarOwnerFiles = () => {
  const { getAuthHeaders } = useProvideAuth();
  const uploadCarOwnerImageFiles = async ({
    hash,
    driverIdPhoto,
    platePhoto,
  }: {
    hash: string;
    driverIdPhoto: File;
    platePhoto: File;
  }) => {
    const form = new FormData();
    form.append('driver_id_photo', driverIdPhoto);
    form.append('plate_photo', platePhoto);
    const response = await fetch(
      `${process.env.REACT_APP_FP_BE_URL}/api/upload-files/${hash}/car-owner`,
      {
        method: 'POST',
        body: form,
        headers: getAuthHeaders(),
      },
    );
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Image upload failed: ${errorMessage}`);
    }
    console.log(response);
    return response;
  };
  return {
    uploadCarOwnerImageFiles,
  };
};
