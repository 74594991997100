import { useLazyQuery, useMutation } from '@apollo/client';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { REPORT_BY_QR_CODE } from 'graphql/queries/reportByQRCode';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { CREATE_CAR_OWNER_FOR_TICKET } from 'graphql/mutations/createCarOwnerForTicket';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useTranslation } from 'react-i18next';
import { ReportProps } from 'types/Report';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledForm = styled.form<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
  }
  .detailTitle {
    @media ${device.laptop} {
      font-size: var(--text-l);
      padding-bottom: 5px;
    }
  }
  .detailContent {
    @media ${device.laptop} {
      font-size: 22px;
    }
  }
  .subtitle {
    margin-bottom: 10px;
    @media ${device.laptop} {
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom: 20px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    button {
      max-width: 125px;
    }
  }
`;
const StyledDatailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media ${device.laptop} {
    gap: 25px;
  }
`;

const StyledDetail = styled.div``;
const StyledDetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 35px;
  @media ${device.laptop} {
    gap: 30px;
  }
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--sub--black);
  text-align: center;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  font-size: var(--text-l);
  @media ${device.laptop} {
    padding-left: 0;
    max-width: max-content !important;
    width: max-content;
  }
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding-bottom: 30px;
  @media ${device.laptop} {
    p {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
`;
const StyledCheckbox = styled.input`
  margin-top: 3px;
`;
const StyledContent = styled.div`
  max-width: 793px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
  }
`;

export const CheckDetails = () => {
  const appContextStore = useContext(appContext);
  const carOwner = appContextStore?.carOwner;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const methods = useForm();
  const params = useParams();
  const navigate = useNavigate();
  const { windowHeight } = useWindowHeight();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [reportData, setReportData] = useState<ReportProps | undefined>(
    undefined,
  );
  const [reportByQRCode, { loading }] = useLazyQuery(REPORT_BY_QR_CODE, {
    onCompleted: (data) => {
      setReportData(data.reportByQRCode);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsError(true);
      throw new Error(error.message);
    },
  });
  const [createCarOwner, { loading: createCarOwnerLoading }] = useMutation(
    CREATE_CAR_OWNER_FOR_TICKET,
    {
      onCompleted: () => {
        setIsLoading(false);
        navigate(`/success-car-owner`);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        throw new Error(error.message);
      },
    },
  );
  useEffect(() => {
    const qrCodeId = params?.qrCodeId;
    if (!qrCodeId) return;
    reportByQRCode({ variables: { qrCodeId: qrCodeId } });
  }, [params]);
  useEffect(() => {
    setIsLoading(loading || createCarOwnerLoading);
  }, [loading, createCarOwnerLoading]);
  const handleSend = () => {
    createCarOwner({
      variables: {
        ...carOwner,
      },
    });
  };
  return (
    <ParkingTicketsLayout>
      <FormProvider {...methods}>
        <StyledForm height={`${windowHeight}px`}>
          <StyledContent>
            <Heading color="var(--black)">{t('chedckDetailsTitle')}</Heading>
            <Paragraph
              dimension="s"
              color="var(--sub--black)"
              className="subtitle"
            >
              {t('checkDetailsDescription')}
            </Paragraph>
            <StyledDetailsList>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    QR-ID
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {reportData?.qrCodeId || ''}
                  </Paragraph>
                </StyledDetail>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    textAlign="right"
                    className="detailTitle"
                  >
                    {t('plate')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    textAlign="right"
                    className="detailContent"
                  >
                    {reportData?.plate || ''}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
              {/* Not required for falschparker.io */}
              {/* <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('countryCode')}
                </Paragraph>
                <Paragraph
                  color="var(--black)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  CH
                </Paragraph>
              </StyledDetail> */}
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('notes')}
                </Paragraph>
                <Paragraph
                  color="var(--black)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  {reportData?.notes || ''}
                </Paragraph>
              </StyledDetail>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('fineAmount')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {process.env.REACT_APP_REGION === 'ch' ? 'CHF' : 'EUR'}{' '}
                    {reportData?.fineValue}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
            </StyledDetailsList>
            <Heading color="var(--black)">
              {t('placeAndDateOfTheOffense')}
            </Heading>
            <StyledDetailsList>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {process.env.REACT_APP_REGION === 'de'
                      ? 'Straße, Hausnummer'
                      : t('streetAndStreetNumber')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {reportData?.street || ''} {reportData?.streetNumber || ''}
                  </Paragraph>
                </StyledDetail>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    textAlign="right"
                    className="detailTitle"
                  >
                    {t('zipCodeAndCity')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    textAlign="right"
                    className="detailContent"
                  >
                    {reportData?.zipCode || ''} {reportData?.city || ''}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
              <StyledDetail>
                <Paragraph
                  dimension="xs"
                  color="var(--sub--black)"
                  className="detailTitle"
                >
                  {t('timeAndDate')}
                </Paragraph>
                <Paragraph
                  color="var(--black)"
                  weight="bolder"
                  dimension="m"
                  className="detailContent"
                >
                  {reportData?.createdAt}
                </Paragraph>
              </StyledDetail>
            </StyledDetailsList>
            <Heading color="var(--black)">
              {t('personalDetailsOfTheDriver')}
            </Heading>
            <StyledDetailsList>
              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('name')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {carOwner?.fullName}
                  </Paragraph>
                </StyledDetail>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    textAlign="right"
                    className="detailTitle"
                  >
                    {t('addressSuffix')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    textAlign="right"
                    className="detailContent"
                  >
                    {carOwner?.fullAddress.split(',')[0]}
                    <br />
                    {carOwner?.fullAddress.split(',')[1]}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>

              <StyledDatailsContainer>
                <StyledDetail>
                  <Paragraph
                    dimension="xs"
                    color="var(--sub--black)"
                    className="detailTitle"
                  >
                    {t('email')}
                  </Paragraph>
                  <Paragraph
                    color="var(--black)"
                    weight="bolder"
                    dimension="m"
                    className="detailContent"
                  >
                    {carOwner?.contactDetails}
                  </Paragraph>
                </StyledDetail>
              </StyledDatailsContainer>
            </StyledDetailsList>
            <StyledCheckboxContainer>
              <StyledCheckbox
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked((prev) => !prev)}
              />
              <Paragraph dimension="s" color="var(--sub--black)">
                {t('checkDetailsInfo')}
              </Paragraph>
            </StyledCheckboxContainer>
          </StyledContent>
          <StyledButtonContainer>
            <Button className="nextButton" onBtnClick={handleSend}>
              {t('send')}
            </Button>
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
                navigate(`/car-driver-details/${params?.qrCodeId}`);
              }}
            >
              {t('back')}
            </StyledButton>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
    </ParkingTicketsLayout>
  );
};
