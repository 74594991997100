import { useProvideAuth } from 'hooks/useProvideAuth';
/**
 * Custom hook for uploading a signature image for a parking zone.
 * Uses authentication headers from `useProvideAuth` for secure requests.
 *
 * @returns {Object} An object containing the `uploadSignature` function.
 */

/**
 * Uploads a signature image for a parking zone.
 *
 * @param {Object} params - The parameters for the upload.
 * @param {string} params.id - The unique identifier for the parking zone.
 * @param {File} params.signatureImage - The signature image file to be uploaded.
 * @returns {Promise<Response>} A promise that resolves with the server response.
 * @throws {Error} If the upload fails.
 *
 * @example
 * ```ts
 * try {
 *   const response = await uploadSignature({
 *     id: 'parking123',
 *     signatureImage: file
 *   });
 *   console.log('Upload successful:', response);
 * } catch (error) {
 *   console.error('Upload failed:', error);
 * }
 * ```
 */
export const useUploadImageForParkingZone = () => {
  const { getAuthHeaders } = useProvideAuth();
  const uploadSignature = async ({
    id,
    signatureImage,
  }: {
    id: string;
    signatureImage: File;
  }) => {
    const formData = new FormData();
    formData.append('files[]', signatureImage);
    const response = await fetch(
      `${process.env.REACT_APP_FP_BE_URL}/api/upload-files/${id}/parking-zone`,
      {
        method: 'POST',
        body: formData,
        headers: getAuthHeaders(),
      },
    );
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Image upload failed: ${errorMessage}`);
    }
    console.log(response);
    return response;
  };
  return { uploadSignature };
};
