import { useTranslation } from 'react-i18next';
import { Confirm } from '../../../Confirm/Confirm';
import { SuccessDriverCard } from 'components/Cards/SuccessDriverCard/SuccessDriverCard';
import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { appContext } from 'views/App';

export const SuccessfulOrder = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={
        (reportDetails?.type === 1 && t('successOrderSubtitle')) ||
        (reportDetails?.type === 2 && t('confirmTicket')) ||
        (reportDetails?.type === 3 && t('gasStationSuccessReport'))
      }
      card={
        reportDetails?.type === 1 ? (
          <SuccessDriverCard status={t('confirmTowingStatus')} />
        ) : (
          <SuccessCard />
        )
      }
      onContinueClick={() => navigate('/')}
    />
  );
};
