import { useLazyQuery } from '@apollo/client';
import { Accordion } from 'components/Accordion/Accordion';
import { IconButton } from 'components/IconButton/IconButton';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { InfoCircleIcon } from 'components/Icons/InfoCircleIcon/InfoCircleIcon';
import { Input } from 'components/Input/Input';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Popup } from 'components/Popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'styles/devices';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { useTranslation } from 'react-i18next';
import { REPORT_BY_QR_CODE_AND_PLATE } from 'graphql/queries/reportByQRCodeAndPlate';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledForm = styled.form<{ height: string }>`
  p {
    line-height: 16px;
  }
  min-height: ${({ height }) => `calc(${height} - 80px - 60px)`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  justify-content: space-between;
  @media ${device.laptop} {
    min-height: ${({ height }) => `calc(${height} - 147px - 92px)`};
  }
  .plateNumberAccordion {
    justify-content: space-between;
    color: rgba(132, 132, 132, 1) !important;
    padding-top: 40px;
    cursor: pointer;
    p {
      color: rgba(132, 132, 132, 1) !important;
      font-weight: 600;
      font-size: 14px;
      @media ${device.laptop} {
        font-size: 24px;
      }
    }
    path {
      stroke: var(--sub--black);
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--sub--black);
    line-height: 0.1em;
    & span {
      background: #fff;
      padding: 0 10px;
    }
  }
  .ticketInput {
    span {
      color: black !important;
    }
  }
`;
const StyledContent = styled.div`
  max-width: 590px;
  @media ${device.laptop} {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .nextButton {
    color: var(--white);
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    button {
      max-width: 125px;
    }
  }
`;
const StyledFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StyledAccordion = styled.div`
  p {
    color: black !important;
  }
`;

export const TicketOrPlateNumber = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const methods = useForm();
  const { setValue, handleSubmit, getValues, watch } = methods;
  const [isNotFoundTicket, setIsNotFoundTicket] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const { windowHeight } = useWindowHeight();

  const convertNewlinesToBreaks = (text: string) => {
    return text.split('\n');
  };

  const [reportByQRCodeAndPlate, { loading }] = useLazyQuery(
    REPORT_BY_QR_CODE_AND_PLATE,
    {
      onCompleted: (data) => {
        if (data?.reportByQRCodeAndPlate) {
          navigate(`/ticket-details/${data?.reportByQRCodeAndPlate?.qrCodeId}`);
        } else {
          setIsNotFoundTicket(true);
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        throw new Error(error.message);
      },
    },
  );
  useEffect(() => {
    if (!params && !params.ticketId) return;
    setValue('ticketId', params.ticketId);
  }, [params]);

  const onSubmit = () => {
    reportByQRCodeAndPlate({
      variables: {
        qrCodeId: getValues('ticketId'),
        plate: getValues('plateNumber'),
      },
    });
  };
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  useEffect(() => {
    localStorage.setItem('isDarkMode', 'false');
  }, []);
  useEffect(() => {
    const newPlate = getValues('plateNumber').toUpperCase().replaceAll(' ', '');
    setValue('plateNumber', newPlate);
  }, [watch('plateNumber')]);
  return (
    <ParkingTicketsLayout>
      <FormProvider {...methods}>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          height={`${windowHeight}px`}
        >
          <StyledContent>
            <Heading color="black">{t('ticketTitle')}</Heading>

            <StyledFormContent>
              <Input
                name="ticketId"
                label={
                  process.env.REACT_APP_REGION === 'de'
                    ? 'Vertragsstrafe-Nr'
                    : t('ticketNumberQR')
                }
                mode="light"
                placeholder="0000000 00 000 0"
                inputClassName="ticketInput"
                isRequired
                rightContent={
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <InfoCircleIcon stroke="var(--sub--black)" size="24px" />
                  </IconButton>
                }
              />
              <Paragraph
                dimension="xs"
                color="var(--sub--black)"
                className="or"
              >
                <span>{t('or')}</span>
              </Paragraph>
              <Input
                name="plateNumber"
                label={t('plateNumber')}
                mode="light"
                placeholder="12345"
                inputClassName="ticketInput"
                isRequired
                rightContent={
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <InfoCircleIcon stroke="var(--sub--black)" size="24px" />
                  </IconButton>
                }
              />
            </StyledFormContent>
            <StyledAccordion>
              <Accordion
                title={t('legalInformation')}
                accordionClassName="plateNumberAccordion"
                elements={convertNewlinesToBreaks(
                  process.env.REACT_APP_REGION === 'de'
                    ? `Falls Sie eine Rechnung wünschen, geben Sie bitte Ihre Halterdaten über unseren QR-Code ein. Erfolgt keine Angabe zum Halter oder Lenker des Fahrzeugs, müssen wir die Halterdaten beim KBA kostenpflichtig anfordern. Die dadurch entstehenden Zusatzkosten werden Ihnen in Rechnung gestellt.
                     Falschparken auf Privatgrund gemäß § 280 Abs. 1, § 286 BGB aus Abtretung gem. § 398 BGB.`
                    : t('legalInformationElement'),
                )}
                elementWeight="normal"
                elementTextTransform="none"
              ></Accordion>
            </StyledAccordion>
          </StyledContent>
          <StyledButtonContainer>
            <Button
              className="nextButton"
              type="submit"
              disabled={Boolean(!watch('plateNumber') && !watch('ticketId'))}
            >
              {t('next')}
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
      <Popup
        isPopupOpened={isNotFoundTicket}
        onDecline={() => setIsNotFoundTicket(false)}
        type="info"
        customerContent={
          <>
            <Heading color="var(--black)" textAlign="center">
              {t('noTicketTitle')}
            </Heading>
            <Paragraph color="var(--sub--black)" textAlign="center">
              {t('noTicketDesc')}
            </Paragraph>
          </>
        }
        confirmText="ok"
      />
    </ParkingTicketsLayout>
  );
};
