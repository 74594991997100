import { ContractPointProps } from 'views/Contract/ContractPoint/ContractPoint';

export const contractPointsCH: ContractPointProps[] = [
  {
    title: 'contractFirstPointTitleCH',
    content: 'contractFirstPointContentCH',
  },
  {
    title: 'contractSecondPointTitleCH',
    content: 'contractSecondPointContentCH',
  },
  {
    title: 'contractThirdPointTitleCH',
    content: 'contractThirdPointContentCH',
  },
  {
    title: 'contractFourthPointTitleCH',
    content: 'contractFourthPointContentCH',
  },
  {
    title: 'contractFifthPointTitleCH',
    content: 'contractFifthPointContentCH',
  },
  {
    title: 'contractSixthPointTitleCH',
    content: 'contractSixthPointContentCH',
  },
  {
    title: 'contractSeventhPointTitleCH',
    content: 'contractSeventhPointContentCH',
  },
  {
    title: 'contractEighthPointTitleCH',
    content: 'contractEighthPointContentCH',
  },
  {
    title: 'contractNinthPointTitleCH',
    content: 'contractNinthPointContentCH',
  },
  {
    title: 'contractTenthPointTitleCH',
    content: 'contractTenthPointContentCH',
  },
  {
    title: 'contractEleventhPointTitleCH',
    content: 'contractEleventhPointContentCH',
  },
  {
    title: 'contract12PointTitleCH',
    content: 'contract12PointContentCH',
  },
];
